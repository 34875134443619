import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal, 
  ModalBody,
  ModalFooter
} from "reactstrap";

// Redux actions (same as category if your server logic merges the entire category list):
import {
  createTpcCourseCategory,
  createTpcCourseCategoryReset,
  updateTpcCourseCategory,
  updateTpcCourseCategoryReset,
  fetchTpcCourses
} from 'redux/ManageTpcCourses/ManageTpcCoursesActions';

import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

const AddTpcCourseSubCategoryModal = (props) => {
  /*
    Props you must pass in from parent (ManageTpcCoursesLessons):
      - modalAddTpcCourseSubcategory: boolean (is Modal open)
      - toggleAddTpcCourseSubcategory: function to open/close modal
      - editSubcategory: boolean (false = create mode, true = edit mode)
      - subcategoryToUpdate: object (the subcat being edited if any)
      - parentCategoryId: number (the ID of the parent category)
      - items: array of all categories (with subcategories inside)
      - courseData: the main TPC course object (with tpcCourseId, etc.)
  */

  const {
    modalAddTpcCourseSubcategory,
    toggleAddTpcCourseSubcategory,
    editSubcategory,
    subcategoryToUpdate,
    parentCategoryId,
    items,
    courseData,
  } = props;

  const dispatch = useDispatch();

  // The local text field for "Subcategory Name"
  const [subCategoryName, setSubCategoryName] = useState("");

  // We'll store here the parent's subcategory list (if found)
  const [subCategoryList, setSubCategoryList] = useState([]);

  // ------------------------------
  // Effect: whenever modal opens/closes, or we have new items from Redux
  // ------------------------------
  useEffect(() => {
    dispatch(fetchTpcCourses({}));

    // If we are editing, set the subcategory name from subcategoryToUpdate
    if (editSubcategory && subcategoryToUpdate && subcategoryToUpdate.text) {
      setSubCategoryName(subcategoryToUpdate.text);
    } else {
      setSubCategoryName("");
    }

    // Load up the parent's subcategory array
    if (items && parentCategoryId) {
      const parent = items.find(cat => cat.id === parentCategoryId);
      if (parent) {
        setSubCategoryList(parent.subCategories || []);
      } else {
        setSubCategoryList([]);
      }
    }
  }, [modalAddTpcCourseSubcategory]);

  // ------------------------------
  // CREATE Subcategory
  // ------------------------------
  const handleCreateSubcategory = (e) => {
    e.preventDefault();
    Swal.showLoading();

    // 1) Clone the entire categories array
    const updatedCategoryLists = [...items];

    // 2) Find the parent category by ID
    const parentIndex = updatedCategoryLists.findIndex(cat => cat.id === parentCategoryId);
    if (parentIndex === -1) {
      Swal.fire("Error", "Parent Category not found!", "error");
      return;
    }

    // 3) Push a new subcat object
    const newSubCatId = updatedCategoryLists[parentIndex].subCategories
      ? updatedCategoryLists[parentIndex].subCategories.length + 1
      : 1;

    const newSubCat = {
      id: newSubCatId,
      text: subCategoryName,
      lessons: []
    };

    // If no subCategories array yet, create it
    if (!updatedCategoryLists[parentIndex].subCategories) {
      updatedCategoryLists[parentIndex].subCategories = [];
    }

    updatedCategoryLists[parentIndex].subCategories.push(newSubCat);

    // 4) Dispatch createTpcCourseCategory or update (the naming in your code can be the same)
    //    Because your server logic merges the entire category array anyway.
    console.log(updatedCategoryLists)
    // return false;
    dispatch(
      createTpcCourseCategory({
        tpcCourseId: courseData.tpcCourseId,
        updatedCategoryLists: updatedCategoryLists
      })
    );
  };

  // ------------------------------
  // UPDATE Subcategory
  // ------------------------------
  const handleUpdateSubcategory = (e) => {
    e.preventDefault();
    Swal.showLoading();

    // 1) Clone the entire categories array
    const updatedCategoryLists = [...items];

    // 2) Find the parent category
    const parentIndex = updatedCategoryLists.findIndex(cat => cat.id === parentCategoryId);
    if (parentIndex === -1) {
      Swal.fire("Error", "Parent Category not found!", "error");
      return;
    }

    // 3) Find the subcategory in that parent's subCategories
    const subCatIndex = updatedCategoryLists[parentIndex].subCategories.findIndex(
      sc => sc.id === subcategoryToUpdate.id
    );
    if (subCatIndex === -1) {
      Swal.fire("Error", "Subcategory not found!", "error");
      return;
    }

    // 4) Update its text
    updatedCategoryLists[parentIndex].subCategories[subCatIndex].text = subCategoryName;

    // 5) Dispatch updateTpcCourseCategory
    dispatch(
      updateTpcCourseCategory({
        tpcCourseId: courseData.tpcCourseId,
        updatedCategoryLists: updatedCategoryLists
      })
    );
  };

  // ------------------------------
  // LISTEN FOR create/update RESULTS
  // (Mirroring your Category approach)
  // ------------------------------
  useEffect(() => {
    if (
      props.createTpcCourseCategory.tpccourse.length &&
      typeof props.createTpcCourseCategory.error === "undefined"
    ) {
      Swal.fire({
        title: 'Success!',
        text: 'Successfully added TPC course subcategory!',
        icon : 'success',
        timer: 3000
      }).then(() => {
        dispatch(createTpcCourseCategoryReset());
        toggleAddTpcCourseSubcategory();
      });
    } else if (typeof props.createTpcCourseCategory.error !== "undefined") {
      Swal.fire({
        title:'Failed!',
        text:'Failed to add TPC course subcategory!',
        icon :'error',
        timer: 3000
      }).then(() => {
        dispatch(createTpcCourseCategoryReset());
        toggleAddTpcCourseSubcategory();
      });
    }
  }, [props.createTpcCourseCategory]);

  useEffect(() => {
    if (
      props.updateTpcCourseCategory.tpccourse.length &&
      typeof props.updateTpcCourseCategory.error === "undefined"
    ) {
      Swal.fire({
        title: 'Success!',
        text: 'Successfully updated TPC course subcategory!',
        icon : 'success',
        timer: 3000
      }).then(() => {
        dispatch(updateTpcCourseCategoryReset());
        toggleAddTpcCourseSubcategory();
      });
    } else if (typeof props.updateTpcCourseCategory.error !== "undefined") {
      Swal.fire({
        title:'Failed!',
        text:'Failed to update TPC course subcategory!',
        icon :'error',
        timer: 3000
      }).then(() => {
        dispatch(updateTpcCourseCategoryReset());
        toggleAddTpcCourseSubcategory();
      });
    }
  }, [props.updateTpcCourseCategory]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="lg"
      isOpen={modalAddTpcCourseSubcategory}
      toggle={toggleAddTpcCourseSubcategory}
    >
      <ModalBody className='modal-body p-0'>
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent">
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">
                  {editSubcategory ? "Edit" : "Add"} Subcategory
                </h3>
              </Col>
              <Col className="text-right" xs="4"></Col>
            </Row>
          </CardHeader>
          <CardBody style={{ maxHeight:"650px", overflowY: 'auto' }}>
            <h6 className="heading-small text-muted mb-4">
              Subcategory Information
            </h6>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">
                      Name <span className='text-danger'>*</span>
                    </label>
                    <Input
                      className="form-control-alternative"
                      type="text"
                      value={subCategoryName}
                      onChange={(e) => setSubCategoryName(e.target.value)}
                      placeholder="Enter subcategory name"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </ModalBody>

      <ModalFooter>
        <Button color="secondary" onClick={toggleAddTpcCourseSubcategory}>
          Cancel
        </Button>
        {!editSubcategory ? (
          <Button color="primary" onClick={handleCreateSubcategory}>
            Save
          </Button>
        ) : (
          <Button color="primary" onClick={handleUpdateSubcategory}>
            Update
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

// ------------------------------
// Redux Connection
// ------------------------------
const mapStateToProps = (state) => {
  return {
    userDetails : state.loginAuth.userDetails,
    createTpcCourseCategory : state.createTpcCourseCategory,
    updateTpcCourseCategory : state.updateTpcCourseCategory,
    fetchTpcCourses : state.fetchTpcCourses
  };
};

export default connect(mapStateToProps)(AddTpcCourseSubCategoryModal);
