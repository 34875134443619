import React, { useState, useEffect } from 'react';
import { Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Form, FormGroup, Label, Input, FormText, CardBody, Card } from 'reactstrap';
import Header from 'components/Headers/Header';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createTimelineSchedule, fetchTimelineSchedule } from "redux/Timeline/TimelineActions";
import { connect, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { premedReqs } from 'util/DataList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Plans from './Plans';

const CardDiv = ({ title, category, color, source, onRemove, onDuplicate, isDefault, isPrevious, onEdit, list }) => {
  const [{ isDragging }, drag] = useDrag({
    type: 'CARD',
    item: { title, category, color, source, isDefault },
    canDrag: !isPrevious, // Prevent dragging if card is from previous data
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });


  return (
    <div
      ref={!isPrevious ? drag : null} // Only attach drag ref if not previous data
      style={{
        opacity: isDragging ? 0.5 : 1,
        backgroundColor: color,
        padding: '10px',
        margin: '5px 0',
        borderRadius: '5px',
        color: '#fff',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: isPrevious ? 'default' : 'move',
        boxShadow: '10px solid',
      }}
    >
      <div>
        <span className='d-block mb-1 text-xs opacity-7'>{category}</span>
        <span className='text-sm'>{title}</span>
      </div>
      {!isPrevious && !list && (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            color="warning"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onDuplicate(title, source);
            }}
          >
            <i class="far fa-clone"></i>
          </Button>
          <Button
            color="danger"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onRemove(title, source);
            }}
          >
            X
          </Button>
        </div>
      )}
    </div>
  );
};

  const SemesterDropZone = ({ season, year, level, cards, onDrop, onRemove, onDuplicate }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'CARD',
    drop: (item) => onDrop(item, season, year, level),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const academicLevelsColor = {
    Freshman : "#2dce89",
    Sophomore : "#ffd600",
    Junior : "#fb6340",
    Senior : "#f5365c",
    Postbac : "#5e72e4",
    Graduate : "#5603ad",
    "Working gap year" : "#8965e0"
  }

  return (
    <div
      ref={drop}
      className='mt-3'
      style={{
        minHeight: '100px',
        border: isOver ? '2.5px solid ' + academicLevelsColor[level] : '2.5px dashed ' + academicLevelsColor[level],
        padding: '10px',
        backgroundColor: isOver ? '#f0f8ff' : '#fafafa',
        borderRadius: '10px',
        transition: 'background-color 0.3s ease',
      }}
    >
      <h4 className="text-sm font-weight-bold" style={{color:academicLevelsColor[level]}}>{level}</h4>
 
      {cards.length > 0 ? (
        cards.map((card, index) => (
          <CardDiv
            key={index}
            title={card.title}
            category={card.category}
            color={card.color}
            isPrevious={card.isPrevious}
            source={{ season, year, level }}
            onRemove={onRemove}
            isDefault={card.isDefault}
            onDuplicate={onDuplicate}
            
          />
        ))
      ) : (
        <p className='text-gray' style={{  textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>Drag cards here</p>
      )}
    </div>
  );
};




const Timeline = (props) => {
  const currentYear = new Date().getFullYear();
  const history = useHistory()

  const initialYear = props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].freshmanYear : typeof props.userDetails.freshmanYear != "undefined" ? props.userDetails.freshmanYear : 2000


  const [years, setYears] = useState([initialYear]);
  const [semesterData, setSemesterData] = useState({});
  const [modal, setModal] = useState(false);
  const [availableCards, setAvailableCards] = useState([]);
  const [tempData, setTempData] = useState({ category: '', title: '', color: '#66b2ff' });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const toggleModal = () => setModal(!modal);

  const handleInputChange = (field, value) => {
    setTempData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: '',
    }));
  };

  const [showPreviousData, setShowPreviousData] = useState(false);
  const [previousData, setPreviousData] = useState([]);

  useEffect(() => {
    dispatch(fetchTimelineSchedule({ user: props.userDetails.handle }));
    
    setSemesterData(initializeSemesterData(initialYear));

    premedReqs.map((value, index) => {
        if(value != "Class not a Premed Requirement" && value != "default"){
            setRequirements((prevRequirements) => ({
                ...prevRequirements,
                [value]: false
            }));
        }
        
    })

    const previousYear = [];

    for (let year = initialYear + 1; year <= initialYear+100; year++) {
      previousYear.push(year);
    }

    setPreviousYearOptions(previousYear)
    
    
    
  }, []);

  const initializeSemesterData = (startYear) => {
    
    const academicLevels = ["Freshman", "Sophomore", "Junior", "Senior", lastAcademicLevel];

    const fullData = {};
    
    let years = []
    for (let i = 0; i < 5; i++) {
      const currentYear = startYear + i;
      const currentLevel = academicLevels[i] || academicLevels[academicLevels.length - 1]; // Stay at Graduate if levels exceed
  
      fullData[currentYear] = {};

      years.push(currentYear)

      seasons.forEach((season) => {
        fullData[currentYear][season] = {
          [currentLevel]: [], // Only include the current academic level for this year
        };
      });
    }

    setYears(years)
    
    return fullData;
  };

  

  useEffect(() => {
      if(props.fetchTimelineSchedule.loading){
        Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
                Swal.showLoading()
                
            }
        });
      }
      else{

        Swal.close()

        let promptOn = false ;
        if(props.updateUserProfile.userprofile.length){
          
          if(typeof props.updateUserProfile.userprofile[0].freshmanYear == "undefined" || props.updateUserProfile.userprofile[0].freshmanYear == ""){
            Swal.fire({
              title: 'Update your profile.',
              text: "Please update your Freshman year in Profile.",
              icon: 'warning',
              confirmButtonColor: "#f5365c",
              confirmButtonText: 'Go to Profile',
              allowOutsideClick: false
            }).then((result) => {

              if (result.isConfirmed) {
                history.push('/client/my-profile')
              }

              promptOn = true;

            });
          }
            
        }
        else {
          if(typeof props.userDetails.freshmanYear == "undefined" || props.userDetails.freshmanYear == ""){
            Swal.fire({
              title: 'Update your profile.',
              text: "Please update your Freshman Year in Profile.",
              icon: 'warning',
              confirmButtonColor: "#f5365c",
              confirmButtonText: 'Go to Profile',
              allowOutsideClick: false
            }).then((result) => {

              if (result.isConfirmed) {
                history.push('/client/my-profile')
              }

            });

            promptOn = true;
          }
        }

        if(!promptOn){
          if(props.updateUserProfile.userprofile.length){
          
            if(typeof props.updateUserProfile.userprofile[0].schoolYear == "undefined" || props.updateUserProfile.userprofile[0].schoolYear == ""){
              Swal.fire({
                title: 'Update your profile.',
                text: "Please update your Current School Year in Profile.",
                icon: 'warning',
                confirmButtonColor: "#f5365c",
                confirmButtonText: 'Go to Profile',
                allowOutsideClick: false
              }).then((result) => {
  
                if (result.isConfirmed) {
                  history.push('/client/my-profile')
                }
  
              });
            }
              
          }
          else {
            if(typeof props.userDetails.schoolYear == "undefined" || props.userDetails.schoolYear == ""){
              Swal.fire({
                title: 'Update your profile.',
                text: "Please update your Current School Year in Profile.",
                icon: 'warning',
                confirmButtonColor: "#f5365c",
                confirmButtonText: 'Go to Profile',
                allowOutsideClick: false
              }).then((result) => {
  
                if (result.isConfirmed) {
                  history.push('/client/my-profile')
                }
  
              });
            }
          }
        }

        if (props.fetchTimelineSchedule && props.fetchTimelineSchedule.timelineschedule && props.fetchTimelineSchedule.timelineschedule.data) {


            setSemesterData((prev) => {
              // console.log(initialYear, "Adjusting keys");
            
              // Clone the timeline data
              const timelineData = { ...props.fetchTimelineSchedule.timelineschedule.data };
            
              // Generate a range of years starting from the new initialYear
              const totalYears = Object.keys(timelineData).length; // Use the existing number of years
              const newYears = Array.from({ length: totalYears }, (_, i) => initialYear + i);
            
              // Map the old data to the new years
              const adjustedData = {};
              newYears.forEach((year, index) => {
                const oldYear = Object.keys(timelineData)[index];
                adjustedData[year] = timelineData[oldYear] || {}; // Copy data from the old year if available
              });
              
              return adjustedData;
            });


            setLastAcademicLevel((prev) => {
              if(typeof props.fetchTimelineSchedule.timelineschedule.lastAcademicLevel != "undefined"){
                return props.fetchTimelineSchedule.timelineschedule.lastAcademicLevel
              }
              else{
                return prev
              }
            })

            let dataYears = [...new Set([
                ...Object.keys(props.fetchTimelineSchedule.timelineschedule.data).map(Number), 
                ...[initialYear].map(Number)
            ])];
            
            dataYears = dataYears.length ? dataYears : [initialYear];

            setYears(dataYears);

            if(typeof props.fetchTimelineSchedule.timelineschedule.availableCards != "undefined"){
                setAvailableCards(props.fetchTimelineSchedule.timelineschedule.availableCards)
            }
            

        }
      }
  }, [props.fetchTimelineSchedule]);


  useEffect(() => {
        
    dispatch(fetchTimelineSchedule({user:props.userDetails.handle}))
    
  }, [props.createTimelineSchedule])

  const handleDrop = (card, season, year, level) => {
   
    // Process the drop event
    setSemesterData((prev) => {
      const updatedData = { ...prev };
  
      // If moving from another drop zone, remove it from the previous location
      if (card.source) {
        const { season: sourceSeason, year: sourceYear, level: sourceLevel } = card.source;
        if (updatedData[sourceYear]?.[sourceSeason]?.[sourceLevel]) {
          // updatedData[sourceYear][sourceSeason][sourceLevel] = updatedData[sourceYear][sourceSeason][sourceLevel].filter(
          //   (existingCard) => existingCard.title !== card.title
          // );

          let removed = false; // Track if the duplicate has been removed
          const updatedCards = updatedData[sourceYear][sourceSeason][sourceLevel].filter((existingCard) => {
              if (existingCard.title === card.title && !removed) {
                  removed = true; // Skip this occurrence and mark as removed
                  return false; // Exclude this card
              }
              return true; // Keep other cards
          });

          updatedData[sourceYear][sourceSeason][sourceLevel] = updatedCards

        }
      }

    
  
      // Initialize year, season, and level if not already present
      updatedData[year] = updatedData[year] || {};
      updatedData[year][season] = updatedData[year][season] || {};
      updatedData[year][season][level] = updatedData[year][season][level] || [];
  
      // Add card to target drop zone
      // if (!updatedData[year][season][level].some((existingCard) => existingCard.title === card.title)) {
        updatedData[year][season][level].push({ ...card, source: { season, year, level } });
     
      // }

      

      
      return updatedData;
    });

    if (!card.isDefault && !card.source) {
      setAvailableCards((prevCards) => {

          let removed = false; // Track if the duplicate has been removed
          const updatedCards = prevCards.filter((availableCard) => {
              if (availableCard.title === card.title && !removed) {
                  removed = true; // Skip this occurrence and mark as removed
                  return false; // Exclude this card
              }
              return true; // Keep other cards
          });

        
          dispatch(createTimelineSchedule({
              data: {...semesterData},
              availableCards : updatedCards,
              // lastAcademicLevel,
              user: props.userDetails.handle
          }));
          
          return updatedCards
      
      });
      
    }
    else{
      dispatch(createTimelineSchedule({
        data: {...semesterData},
        availableCards : availableCards,
        // lastAcademicLevel,
        user: props.userDetails.handle
      }));
    }

  };
  

  const removeCard = (title, source) => {
    const { season, year, level } = source; // Include level in destructuring

    setSemesterData((prev) => {

        let removed = false; // Track if the duplicate has been removed
        const updatedCards = prev[year][season][level].filter((card) => {
            
            if (card.title === title && !removed) {
              
                removed = true; // Skip this occurrence and mark as removed
                return false; // Exclude this card
            }
            return true; // Keep other cards
        });

        
        const updatedData = {
            ...prev,
            [year]: {
                ...prev[year],
                [season]: {
                    ...prev[year][season],
                    [level]: updatedCards // Filter out the card within the specific level
                }
            }
        };

        dispatch(createTimelineSchedule({
          data: updatedData,
          availableCards: availableCards,
          // lastAcademicLevel,
          user: props.userDetails.handle
        }));
        

        return updatedData;
    });

    

  };


  const deleteYear = (year) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "This will delete all data for this year.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: "#f5365c",
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setYears((prevYears) => prevYears.filter((y) => y !== year));
        setSemesterData((prevData) => {
          const updatedData = { ...prevData };
          delete updatedData[year];

          dispatch(createTimelineSchedule({
            data: updatedData,
            availableCards : availableCards,
            // lastAcademicLevel,
            user: props.userDetails.handle
          }));
          
          return updatedData;
        });
      }
    });
  };


  const addCustomCard = () => {
    if (!tempData.category || !tempData.title) {
      setErrors({
        category: !tempData.category ? 'Category is required' : '',
        title: !tempData.title ? 'Title is required' : ''
      });
      return;
    }

    const newCard = { ...tempData, isDefault: false };
    setAvailableCards((prev) => {
        
        dispatch(createTimelineSchedule({
            data: semesterData,
            availableCards : [...prev, newCard],
            // lastAcademicLevel,
            user: props.userDetails.handle
        }));

        return [...prev, newCard]
    
    });
    
    toggleModal();
  };

  const timelineIcons = {
    Spring: { icon: "fas fa-leaf", color: "#2dce89" },
    Summer: { icon: "fas fa-sun", color: "#ffd600" },
    Fall: { icon: "fab fa-canadian-maple-leaf", color: "#fb6340" },
    Winter: { icon: "fas fa-snowflake", color: "#11cdef" },
  };

  const removeAvailableCard = (title) => {
    setAvailableCards((prev) => {
        

      let removed = false; // Track if the duplicate has been removed
      const updatedCards = prev.filter((card) => {
          if (card.title === title && !removed) {
              removed = true; // Skip this occurrence and mark as removed
              return false; // Exclude this card
          }
          return true; // Keep other cards
      });


      dispatch(createTimelineSchedule({
        data: semesterData,
        availableCards : updatedCards,
        // lastAcademicLevel,
        user: props.userDetails.handle
      }));

      return updatedCards
    
    });

    

    
  };
  
  const [requirements, setRequirements] = useState({});

    useEffect(() => {
        if(props.fetchCourses.courses.length){
            props.fetchCourses.courses.map((value, index) => {
                if(value.reqs.length){
                    value.reqs.map((reqsValue, reqsIndex) => {
                        if(reqsValue != "default" && reqsValue != "Class not a Premed Requirement"){
                            
                            setRequirements((prevRequirements) => ({
                                ...prevRequirements,
                                [reqsValue]: true
                            }));

                           
                        }
                    })
                }
            
            });

            setPreviousData(props.fetchCourses.courses);

        }
    }, [props.fetchCourses])

  const seasons = ["Fall","Winter" , "Spring", "Summer"];
  
  
  const isGraduateExist = () => {
    // Check if any year's data includes "Graduate"
    return Object.values(semesterData).some((yearData) =>
      Object.values(yearData).some((seasonData) =>
        Object.keys(seasonData).includes("Graduate")
      )
    );
  };

  // Helper function to get combined data based on the `showPreviousData` toggle
  // Deep copy utility function
  const deepCopy = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  function getYearAndSeason(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScript months are zero-based
    
    let season;
    if (month >= 3 && month <= 5) {
      season = 'Spring';
    } else if (month >= 6 && month <= 8) {
      season = 'Summer';
    } else if (month >= 9 && month <= 11) {
      season = 'Fall';
    } else {
      season = 'Winter';
    }
  
    return { year, season };
  }


  const getDisplayData = () => {
    const combinedData = deepCopy(semesterData);
    
    // Only include previous data if showPreviousData is true
    

    let existedClasses = [];
    previousData.forEach((prev) => {
       

        Object.keys(combinedData).forEach((year) => {

          prev.reqs.forEach((title) => {
            
            if(!existedClasses.includes(title)){

  
              if(combinedData[year][prev.term][String(prev.section[0]).toUpperCase() + String(prev.section).slice(1)]){

                
                  combinedData[year][prev.term][String(prev.section[0]).toUpperCase() + String(prev.section).slice(1)].push({
                    title,
                    category: "Classes (Log Classes)",
                    color: '#f5365c',
                    isDefault: true,
                    isPrevious: true, // Mark as previous data
                  });

                  
                  existedClasses.push(title)
                
              }
              
              
            }
            
          })

        })

    });

    if(!showPreviousData){
      
      let hidePreviousAcademic = false;
      Object.keys(combinedData).forEach((year, index) => {
        Object.keys(combinedData[year]).forEach((season) => {
          
          const schoolYearProfile = props.updateUserProfile.userprofile.length ? typeof props.updateUserProfile.userprofile[0].schoolYear == "undefined" || props.updateUserProfile.userprofile[0].schoolYear == "" ? "freshman"  : props.updateUserProfile.userprofile[0].schoolYear : typeof props.userDetails.schoolYear == "undefined" || props.userDetails.schoolYear == "" ?  "freshman" : props.userDetails.schoolYear;

          if(combinedData[year] &&
            combinedData[year][season] && typeof combinedData[year][season][String(schoolYearProfile[0]).toUpperCase() + String(schoolYearProfile).slice(1)] != "undefined"){
              
              hidePreviousAcademic = true;
           
          }
          else {
            if(schoolYearProfile == "postbac" || schoolYearProfile == "graduate"){
              const academicAfterSeniors = ["Working gap year", "Postbac", "Graduate"]

              academicAfterSeniors.forEach((academicAfterSenior)=> {
                if(combinedData[year] &&
                  combinedData[year][season] && typeof combinedData[year][season][academicAfterSenior] != "undefined"){
                    
                    hidePreviousAcademic = true;
                 
                }
              })

            }
          }
          // if(schoolYearProfile == "postbac" || schoolYearProfile == "graduate" ){
          //   hidePreviousAcademic = false;
          // }

          if(!hidePreviousAcademic){
            delete combinedData[year]
          }

        });
      });
    }

    
    if(typeof props.userDetails.mcatDate != "undefined" && props.userDetails.mcatDate != "" ){
      const { year, season } = getYearAndSeason(props.userDetails.mcatDate);
      
      if (combinedData[year] && combinedData[year][season]) {
        Object.entries(combinedData[year][season]).forEach(([key, value]) => {
          
          if (Array.isArray(value)) {
            // value.push("Dynamic Value"); // Modify the array

            combinedData[year][season][key].push({
              title: "Take MCAT",
              category: "Premed Milestones (My Profile)",
              color: '#f5365c',
              isDefault: true,
              isPrevious: true, // Mark as previous data
            });

          }
        });

      }


    }
    
    

    
    
    return combinedData;
  };


  useEffect(() => {
    
    premedReqs.map((value, index) => {
      if(value != "Class not a Premed Requirement" && value != "default"){
          setRequirements((prevRequirements) => ({
              ...prevRequirements,
              [value]: false
          }));
      }
      
    })

    Object.entries(otherCards).map(([key, value],index) => {
        
          setOtherCards((prevOtherCards) => ({
              ...prevOtherCards,
              [key]: false
          }));
      
      
    })


    
    Object.keys(semesterData).forEach((year, index) => {
      Object.keys(semesterData[year]).forEach((season) => {
        Object.keys(semesterData[year][season]).forEach((level) => {
          semesterData[year][season][level].map(val => {
            

            if(val.title in requirements){

                setRequirements((prevRequirements) => ({
                    ...prevRequirements,
                    [val.title]: true
                }));

            }

            if(val.title in otherCards){

              setOtherCards((prevOtherCards) => ({
                  ...prevOtherCards,
                  [val.title]: true
              }));

            }
            
          })
          
        })
        
      })
    
    })
    
    
  }, [semesterData])
  


  // Function to toggle showPreviousData and refresh displayData
  const togglePreviousDataDisplay = () => {
    setShowPreviousData((prev) => !prev);
  };


  const [inputYear, setInputYear] = useState(''); // Stores selected year for previous year addition
  const [showYearModal, setShowYearModal] = useState(false);

  const [previousYearOptions, setPreviousYearOptions] = useState([])
  // Dropdown options for previous years only
  

  // Toggle the modal for selecting a previous year
  const toggleYearModal = () => setShowYearModal(!showYearModal);

  // Function to add a previous year by opening the modal
  const addYear = () => {
    const lastYear = years[years.length - 1];
   
    const yearToAdd = parseInt((lastYear+1));
  
    // if (yearToAdd && !years.includes(yearToAdd)) {
    const sortedYears = [...years, yearToAdd].sort(); // Ensure years remain sorted
    // const nextLevel = getAcademicLevelSequential(initialYear, "Fall", sortedYears);
    
    setYears(sortedYears);
    setSemesterData((prevData) => {
      const updatedData =  ({
        ...prevData,
        [yearToAdd]: seasons.reduce((seasonAcc, season) => {
          seasonAcc[season] = { ["Working gap year"]: [] }; // Assign the correct academic level
          return seasonAcc;
        }, {}),
      })
      
      dispatch(
        createTimelineSchedule({
          data: updatedData,
          availableCards,
          // lastAcademicLevel : newLevel,
          user: props.userDetails.handle,
        })
      );

      return updatedData

    });


    
    
    // Swal.fire({
    //   title: "Success!",
    //   text: `${yearToAdd} added as ${nextLevel}`,
    //   icon: "success",
    // });

    // const previousYear = [];

    // for (let year = yearToAdd + 1; year <= yearToAdd+100; year++) {
    //   previousYear.push(year);
    // }

    // setPreviousYearOptions(previousYear)


    // } else {
    //   Swal.fire({
    //     title: "Failed!",
    //     text: "This year already exists or is invalid.",
    //     icon: "error",
    //   });
    // }


    // toggleYearModal();
  };

  const confirmAddYear = () => {
    const yearToAdd = parseInt(inputYear);
  
    if (yearToAdd && !years.includes(yearToAdd)) {
      const sortedYears = [...years, yearToAdd].sort(); // Ensure years remain sorted
      const nextLevel = getAcademicLevelSequential(initialYear, "Fall", sortedYears);
  
      setYears(sortedYears);
      setSemesterData((prevData) => ({
        ...prevData,
        [yearToAdd]: seasons.reduce((seasonAcc, season) => {
          seasonAcc[season] = { [nextLevel]: [] }; // Assign the correct academic level
          return seasonAcc;
        }, {}),
      }));
  
      Swal.fire({
        title: "Success!",
        text: `${yearToAdd} added as ${nextLevel}`,
        icon: "success",
      });

      const previousYear = [];

      for (let year = yearToAdd + 1; year <= yearToAdd+100; year++) {
        previousYear.push(year);
      }

      setPreviousYearOptions(previousYear)


    } else {
      Swal.fire({
        title: "Failed!",
        text: "This year already exists or is invalid.",
        icon: "error",
      });
    }
  
    setInputYear('');
    toggleYearModal();
  };


  const academicLevels = ["Freshman", "Sophomore", "Junior", "Senior", lastAcademicLevel];

  const getAcademicLevelSequential = (initialYear, startSeason, years) => {
    const totalAddedYears = years.length - 1; // The total years added, excluding the initial year
    return academicLevels[Math.min(totalAddedYears, academicLevels.length - 1)];
  };
  
  const getDisplayYear = (year, season) => {
    if (season === "Winter") {
      return `${year}/${parseInt(year) + 1}`; // Winter spans two years
    } else if (season === "Spring" || season === "Summer") {
      return parseInt(year) + 1; // Spring and Summer occur in the next calendar year
    }
    return year; // Fall stays in the same year
  };
  

  const duplicateAvailableCard = (title) => {
    setAvailableCards((prev) => {
      const cardToDuplicate = prev.find((card) => card.title === title);
      if (cardToDuplicate) {
        const duplicatedCard = { ...cardToDuplicate, title: `${cardToDuplicate.title}` };
        dispatch(createTimelineSchedule({
          data: semesterData,
          availableCards: [...prev, duplicatedCard],
          // lastAcademicLevel,
          user: props.userDetails.handle,
        }));
        return [...prev, duplicatedCard];
      }
      return prev;
    });
  };


  const duplicateZoneCard = (title, source) => {
    const { season, year, level } = source;
  
    setSemesterData((prev) => {
      const updatedData = { ...prev };
      const sourceZone = updatedData[year]?.[season]?.[level] || [];
      const cardToDuplicate = sourceZone.find((card) => card.title === title);
  
      if (cardToDuplicate) {
        const duplicatedCard = { ...cardToDuplicate, title: `${cardToDuplicate.title}` };
        updatedData[year][season][level] = [...sourceZone, duplicatedCard];
  
        dispatch(createTimelineSchedule({
          data: updatedData,
          availableCards,
          // lastAcademicLevel,
          user: props.userDetails.handle,
        }));
      }
  
      return updatedData;
    });
  };

  const [lastAcademicLevel, setLastAcademicLevel] = useState("Working gap year"); // Default to "Graduate"
  const academicOptions = ["Working gap year", "Postbac", "Graduate"];

  // const lastYear = Math.max(...Object.keys(semesterData).map(Number));

  const handleAcademicLevelChange = (newLevel, year) => {

    setLastAcademicLevel(newLevel);
  
    setSemesterData((prev) => {
      const updatedData = { ...prev };
  
      if (updatedData[year]) {
        seasons.map((season, index) => {

          const currentLevel = Object.keys(updatedData[year][season])[0]; // Get current level
          const currentCards = updatedData[year][season][currentLevel] || []; // Get cards in the current level
    
          // Move cards to the new level
          delete updatedData[year][season][currentLevel];
          updatedData[year][season][newLevel] = currentCards;

        });
       
      }
      

      dispatch(
        createTimelineSchedule({
          data: updatedData,
          availableCards,
          // lastAcademicLevel : newLevel,
          user: props.userDetails.handle,
        })
      );

      return updatedData;
    });
    
    
    
  };

  const [otherCards, setOtherCards] = useState({
    "Start MCAT Study" : false,
    "Start working on secondaries": false,
    "Start getting interviews": false,
    "Take MCAT": false,
    "Start Personal Statement": false,
    "Submit Application": false,
    "Premed Milestone": false
  })

  return (
    <DndProvider backend={HTML5Backend}>
      <Header />
      <Container className="mt--7 bg-info pb-9" fluid>
        <Row>
          <Col className="text-lg-center">
            <p className="display-2 text-white font-weight-bolder">
              <i className="fas fa-stream"></i> Timeline
            </p>
          </Col>
        </Row>

        <Row>
          {/* Default and Available Cards Section */}

          <Col md={4} className='mb-5 mt-5'>
            <Card  style={{position:"sticky", top:"50px" , maxHeight:"90vh", overflowY:"auto"}}>
            {/* {renderLastAcademicLevelDropdown()} */}
                <CardBody>
                    <h3 className='font-weight-bolder text-default'>Premed Requirements :</h3>

                    {Object.entries(requirements).map(([key, value], index) => {
                        if(!value){                        
                            if(key !== "Class not a Premed Requirement"){

                                return (
                                    <CardDiv key={index} title={key} category={'Classes'} color={'#8965e0'} isDefault={true} list={true} />
                                )
                                
                            }
                        }
                    })}

                    <h3 className='mt-4 font-weight-bolder text-default'>Premed Milestones :</h3>
                
                    {Object.entries(otherCards).map(([key, value], index) => {
                      if(!value){  
                        return (
                            <CardDiv key={index} title={key} category={'Premed Milestones'} color={'#fb6340'} isDefault={true} list={true} />
                        )
                      }
                        
                    })}
                    
                
                    <h3 className="mt-4 font-weight-bolder text-default">Custom Cards :</h3>
                    {availableCards.map((card, index) => (
                    <CardDiv
                        key={index}
                        title={card.title}
                        category={card.category}
                        color={card.category == "Classes" ? "#11cdef" : card.category == "Extracurriculars" ? "#2dce89" : "#172b4d"}
                        onRemove={removeAvailableCard}
                        onDuplicate={duplicateAvailableCard}
                    />
                    ))}
                    <Button color="primary" className='mt-3 mb-4' onClick={toggleModal}>Add Custom Card</Button>
                </CardBody>
            </Card>
          </Col>


          <Col md={8}>

        <VerticalTimeline lineColor="#5603ad">
          <VerticalTimelineElement
            iconStyle={{
              cursor: "pointer",
              background: props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].schoolYear == "freshman" ? '#c0c0c0' : "#5e72e4" : typeof props.userDetails.schoolYear != "undefined" ? props.userDetails.schoolYear == "freshman" ? '#c0c0c0' : "#5e72e4" : '#c0c0c0', 
              color: '#fff',
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            icon={<i className={(showPreviousData ? "fas fa-eye-slash" : "fas fa-eye") + " text-xl w-100 h-100 d-flex align-items-center justify-content-center"} 
            onClick={ props.updateUserProfile.userprofile.length ? props.updateUserProfile.userprofile[0].schoolYear == "freshman" ? null : togglePreviousDataDisplay : typeof props.userDetails.schoolYear != "undefined" ? props.userDetails.schoolYear == "freshman"  ? null : togglePreviousDataDisplay : null}></i>}
            date={showPreviousData ? "Hide Previous Data" : "Show Previous Data"}
            dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
          />

          {Object.entries(getDisplayData()).map(([year, seasonsData]) => (
            <div key={year}>
              <div className="mt-6 mb-0" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className='w-50 d-flex d-sm-none'></h2>
                <h2 className='w-100 d-none d-sm-flex'></h2>
                {/* <h2 className='w-100'></h2> */}
                {/* {parseInt(year) !== initialYear && (
                  <Button color="danger" onClick={() => deleteYear(year)}>
                    <i className="fas fa-trash"></i> Delete {year}
                  </Button>
                )} */}
                

                {typeof seasonsData["Fall"]["Working gap year"] != "undefined" || typeof seasonsData["Fall"]["Postbac"] != "undefined" || typeof seasonsData["Fall"]["Graduate"] != "undefined" ? (
                  <div className='d-block w-75 text-right'>

                  <Button color="danger" className='mb-4' onClick={() => deleteYear(year)}>
                    <i className="fas fa-trash"></i> Delete {year}
                  </Button>

                  <p className='mb-0 pb-0 text-white font-weight-bold text-left'>Select to change academic level</p>

                  <select
                    value={Object.keys(seasonsData["Fall"])[0]}
                    onChange={(e) => handleAcademicLevelChange(e.target.value, year)}
                    className="form-control form-control-sm  mt-1 mb-0"
                   
                    // defaultValue={lastAcademicLevel}
                  >
                    {academicOptions.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select></div>
                ) : ""}

              </div>
             
              

              {Object.entries(seasonsData).map(([season, seasonData]) => {

                const displayYear = getDisplayYear(year, season);

                // const level = getAcademicLevelSequential(initialYear, "Fall", years.slice(0, years.indexOf(parseInt(year)) + 1));

                
                return (
                  <VerticalTimelineElement
                    key={`${season}-${year}`}
                    className="vertical-timeline-element--work"
                    dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
                    date={`${season} ${displayYear}`}
                    iconStyle={{ background: timelineIcons[season].color, color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
                    icon={<i className={timelineIcons[season].icon + " text-xl"}></i>}
                  >
                    <h3 className="vertical-timeline-element-title">{`${season} ${displayYear} (${Object.keys(seasonData)[0]})`}</h3>
                    
                    
                    
                    <SemesterDropZone
                      key={`${season}-${year}-${Object.keys(seasonData)[0]}`}
                      season={season}
                      year={year}
                      level={Object.keys(seasonData)[0]}
                      cards={(seasonsData[season][Object.keys(seasonData)[0]] || []).map((card) => ({
                        ...card,
                        color: card.isPrevious ? '#d3d3d3' : card.color,
                      }))}
                      onDrop={handleDrop}
                      onRemove={removeCard}
                      onDuplicate={duplicateZoneCard}
                    />
                  </VerticalTimelineElement>
                );
              })}
            </div>
          ))}
          
          <VerticalTimelineElement
            iconStyle={{  background: '#5e72e4', color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
            icon={<i className="fas fa-plus text-xl w-100 h-100 d-flex align-items-center justify-content-center" 
              onClick={addYear}
              
              ></i>}
            date="Add Next Year"
            dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
          />
          
          <VerticalTimelineElement
            iconStyle={{  background: '#5e72e4', color: '#fff', display: "flex", alignItems: "center", justifyContent: "center" }}
            icon={<i className="fas fa-flag-checkered text-xl w-100 h-100 d-flex align-items-center justify-content-center" 
              // onClick={isGraduateExist() ? null : addYear}
              
              ></i>}
            date="Start Med School!"
            dateClassName="text-xl font-weight-bold text-white pt-2 opacity-10 d-none d-xl-block d-md-none pl-2 pr-2"
          />
          
          </VerticalTimeline>

          </Col>
        </Row>
        <Plans ></Plans>
        {/* Modal for adding a custom card */}
        <Modal isOpen={modal} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Add Custom Card</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="category">Category&nbsp;<span className='text-danger'>*</span></Label>
                <Input
                  type="select"
                  id="category"
                  value={tempData.category}
                  onChange={(e) => handleInputChange('category', e.target.value)}
                >
                  <option value="" disabled>Select a category</option>
                  <option value="Classes">Classes</option>
                  <option value="Extracurriculars">Extracurriculars</option>
                  <option value="Other">Other</option>
                </Input>
                {errors.category && <FormText color="danger">{errors.category}</FormText>}
              </FormGroup>
              <FormGroup>
                <Label for="title">Title <span className='text-danger'>*</span></Label>
                {tempData.category === "Classes" ? (
                  <Input
                    type="select"
                    id="title"
                    value={tempData.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                  >
                    <option value="" disabled>Select a class</option>
                    {premedReqs.map((classTitle, index) => {
                      
                      if (classTitle != "Class not a Premed Requirement"){
                        return (
                        <option key={index} value={classTitle}>{classTitle}</option>
                        )
                      }
                    })}
                  </Input>
                ) : (
                  <Input
                    type="text"
                    id="title"
                    value={tempData.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                  />
                )}
                {errors.title && <FormText color="danger">{errors.title}</FormText>}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={addCustomCard}>Save</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={showYearModal} toggle={toggleYearModal}>
          <ModalHeader toggle={toggleYearModal}>Select a Next Year</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="inputYear">Year</Label>
                <Input
                  type="select"
                  id="inputYear"
                  value={inputYear}
                  onChange={(e) => setInputYear(e.target.value)}
                >
                  <option value="">Select a year</option>
                  {previousYearOptions.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={confirmAddYear}>Add Year</Button>
            <Button color="secondary" onClick={toggleYearModal}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </Container>
    </DndProvider>
  );
};

const mapStateToProps = (state) => ({
    userDetails: state.loginAuth.userDetails,
    fetchTimelineSchedule: state.fetchTimelineSchedule,
    createTimelineSchedule : state.createTimelineSchedule,
    fetchCourses : state.fetchCourses,
    updateUserProfile : state.updateUserProfile,
});

export default connect(mapStateToProps)(Timeline);
