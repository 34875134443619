import React, { useState, useEffect } from 'react'
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Container,
    CardText,
    Button,
    Row,
    Col,
    CardImg,
    TabContent, TabPane, Nav, NavItem, NavLink, Table, Media,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Collapse
    
} from "reactstrap";

import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
import BuyTpcCourseModal from "components/Modal/BuyTpcCourseModal";
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Swal from 'sweetalert2'
import { updateMarkedCompleteLesson, updateMarkedCompleteLessonReset, fetchMarkedCompleteLesson } from 'redux/ManageTpcCourses/ManageTpcCoursesActions';
import { useDispatch,connect } from 'react-redux';
import LiveSchedule from '../Card/LiveSchedule/LiveSchedule'



const TpcCoursesLessons = (props) => {
    
    let firstLesson = 0;
    const [paid, setPaid] = useState(props.courseData.payment)

    const [modalLiveSchedule, setModalLiveSchedule] = useState(false);

    const toggleModalLiveSchedule = (e, spreadSheetId) => {
        setSpreadSheetId(spreadSheetId);
        setModalLiveSchedule(!modalLiveSchedule);
        
    };
    //   const result = []
    //     tpcPaymentRecord.map((pay,i) => (
    //         cid == pay[i].collectionDocId ? result.push(pay[i]) : ""
    //      ))
    //     return result          

    const embedContainerStyle = {
        position: 'relative',
        paddingBottom: '56.25%',
        height: 0,
        overflow: 'hidden',
        maxWidth: '100%',
        borderRadius: "5px", 
    };

    const iframeStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    };

    const [selectedLesson, setSelectedLesson] = useState({})

    const handleSelectLesson = (e, lesson, categoryId, status) => {
        
        e.preventDefault();

        lesson.tpcCourseId = props.courseData.tpcCourseId
        lesson.categoryId = categoryId
        lesson.status = status
        
        setSelectedLesson(lesson)

        const element = document.getElementById('top-div');
    
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start', // Align to the top of the element
          });
        }

    }

    const modalBuyTpcCourseLessonModal = props.buyTutoringHoursModalStatus.status;
    const [buyLesson, setBuyLesson] =  useState([])
    const toggleBuyTpcCourseLessonModal = (e) => {
        // setBuyLesson(lesson)
        dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
    };

    const handleContinueCourse = (e) => {
        e.preventDefault()
        const element = document.getElementById('top-div');
    
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start', // Align to the top of the element
          });
        }
    };

    const [lessonsTaken, setLessonsTaken] = useState([]);
    
    useEffect(() => {

        dispatch(fetchMarkedCompleteLesson({handle: props.userDetails.handle}))

        
        
        
    }, [])
    
    const dispatch = useDispatch()

    const handleMarkAsComplete = (e, selectedLesson) => {
        e.preventDefault()
      
        Swal.showLoading()
        
        dispatch(updateMarkedCompleteLesson({handle : props.userDetails.handle, markedLesson : selectedLesson}))

        let lesson = selectedLesson;
        lesson.status = "completed"
        setSelectedLesson(lesson)

    }
    
    useEffect(() => {
    
  
        if(props.fetchMarkedCompleteLesson.markedcompletelesson.length){
            Swal.showLoading()
            setLessonsTaken(props.fetchMarkedCompleteLesson.markedcompletelesson)
     
            
        }
      
      
    }, [props.fetchMarkedCompleteLesson])

    const handleNextLesson = (e) => {
        e.preventDefault();
    
        const categoryData = props.courseData.category;

        let foundNext = false;
    
        const currentCatId = selectedLesson.categoryId;
        const currentSubCatId = selectedLesson.subCategoryId || null;
        const currentLessonId = selectedLesson.id; // the lesson's 1-based ID
    
        // A helper function to set the next lesson
        const setAndBreak = (lessonObj, catIdx, subCatId = null, lessonIndex) => {
            lessonObj.tpcCourseId = props.courseData.tpcCourseId;
            lessonObj.categoryId = catIdx;
            lessonObj.subCategoryId = subCatId ? subCatId : null;
            
            if(subCatId){
                lessonObj.status =
                typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                    "category" + catIdx
                ]?.["subcategory" + subCatId]?.["lesson" + lessonIndex] != "undefined"
                    ? "completed"
                    : "not completed";
            }
            else{
                lessonObj.status =
                typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                    "category" + catIdx
                ]?.["lesson" + lessonIndex] != "undefined"
                    ? "completed"
                    : "not completed";
            }
            
    
            setSelectedLesson(lessonObj);
            foundNext = true;
            scrollToTopDiv();
        };

        // Iterate through categories starting from the current category
        for (let catIndex = currentCatId; catIndex <= categoryData.length; catIndex++) {
            const catObj = categoryData[catIndex - 1];
            if (!catObj) continue;
    
            // If we're currently in a subcategory, find the next lesson there first
            if (currentSubCatId && catIndex == currentCatId) {
                

                const scIndex = catObj.subCategories?.findIndex(sc => sc.id == currentSubCatId);

                if (scIndex != -1 && scIndex != undefined) {
                    const subCat = catObj.subCategories[scIndex];
    
                    // Try to find the next lesson within the current subcategory
                    for (let i = 0; i < subCat.lessons.length; i++) {
                        const subLesson = subCat.lessons[i];
                        if (subLesson.id > currentLessonId && subLesson.visibility == "published") {
        
                            setAndBreak(subLesson, catIndex, subCat.id, subLesson.id);
                            return;
                        }
                    }
    
                    // Try the next subcategories
                    for (let sc = scIndex + 1; sc < (catObj.subCategories || []).length; sc++) {
                        const sub = catObj.subCategories[sc];
                        if (!sub.lessons) continue;
    
                        for (let j = 0; j < sub.lessons.length; j++) {
                            if (sub.lessons[j].visibility == "published") {
                                setAndBreak(sub.lessons[j], catIndex, sub.id, sub.lessons[j].id);
                                return;
                            }
                        }
                    }
                }
            }
    
            // If in a top-level lesson, check for remaining lessons within the same category
            if (!currentSubCatId && catIndex == currentCatId) {


                for (let t = 0; t < (catObj.lessons || []).length; t++) {
                    const topLesson = catObj.lessons[t];
                    if (topLesson.id > currentLessonId && topLesson.visibility == "published") {
                        setAndBreak(topLesson, catIndex, null, topLesson.id);
                        return;
                    }
                }
    
                // If no more top-level lessons, try subcategories in the same category
                for (let sc = 0; sc < (catObj.subCategories || []).length; sc++) {
                    const sub = catObj.subCategories[sc];
                    if (!sub.lessons) continue;
    
                    for (let j = 0; j < sub.lessons.length; j++) {
                        if (sub.lessons[j].visibility == "published") {
                            setAndBreak(sub.lessons[j], catIndex, sub.id, sub.lessons[j].id);
                            return;
                        }
                    }
                }
            }
    
            // If no lessons left in this category, move to the next category
            if (catIndex > currentCatId) {

            

                for (let t = 0; t < (catObj.lessons || []).length; t++) {
                    if (catObj.lessons[t].visibility == "published") {
                        setAndBreak(catObj.lessons[t], catIndex, null, catObj.lessons[t].id);
                        return;
                    }
                }
    
                for (let sc = 0; sc < (catObj.subCategories || []).length; sc++) {
                    const sub = catObj.subCategories[sc];
                    if (!sub.lessons) continue;
    
                    for (let j = 0; j < sub.lessons.length; j++) {
                        if (sub.lessons[j].visibility == "published") {
                            setAndBreak(sub.lessons[j], catIndex, sub.id, sub.lessons[j].id);
                            return;
                        }
                    }
                }
            }
        }
    
        // If we never found a “next” lesson, show completion message
        Swal.fire({
            title: 'Congratulations!',
            text: 'You have reached the end of the lessons.',
            icon: 'success',
            timer: 3000
        });
    };
    
      
      
      // Helper: scroll to the top-div if it exists
      function scrollToTopDiv() {
        const element = document.getElementById('top-div');
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }
      
    
    
    useEffect(() => {
        if(lessonsTaken.length != 0){

            

            const categoryArray = props.courseData.category
            
            if(typeof lessonsTaken[0][props.courseData.tpcCourseId] != "undefined"){

                const entries = [];
                const data = lessonsTaken[0][props.courseData.tpcCourseId]
                for (const category in data) {
                    for (const subOrLesson in data[category]) {
                        const item = data[category][subOrLesson];

                        if (item.updatedAt) {
                        // Direct lesson
                        entries.push({
                            category,
                            subcategory: null,
                            lesson: subOrLesson,
                            updatedAt: item.updatedAt
                        });
                        } else {
                        // Subcategory containing lessons
                        for (const lesson in item) {
                            entries.push({
                            category,
                            subcategory: subOrLesson,
                            lesson,
                            updatedAt: item[lesson].updatedAt
                            });
                        }
                        }
                    }
                }

                // Sort by updatedAt descending
                entries.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

                // Log results

                let breakLoop = 0;

                entries.forEach(({ category, subcategory, lesson }) => {
                    
                    // console.log(`Category: ${category}, Subcategory: ${subcategory || 'N/A'}, Lesson: ${lesson}`);

                    if(breakLoop == 0){
                        if(subcategory){
                            
                            if(category != "categoryundefined"){
                                let initialSelectedLesson = categoryArray[category.match(/\d+/)[0] - 1].subCategories[subcategory.match(/\d+/)[0] - 1].lessons[lesson.match(/\d+/)[0] - 1]

                                if(initialSelectedLesson && initialSelectedLesson.visibility == "published"){
                                    // initialSelectedLesson.tpcCourseId = props.courseData.tpcCourseId
                                    // initialSelectedLesson.categoryId = props.courseData.category[categoryIndex-1].id
                                    initialSelectedLesson.status = "completed"
                                    setSelectedLesson(initialSelectedLesson)
                                    breakLoop++;
                                    console.log(initialSelectedLesson)
                                }
                            }

                        }
                        else{
                        
                            if(category != "categoryundefined"){

                            
                                let initialSelectedLesson = categoryArray[category.match(/\d+/)[0] - 1].lessons[lesson.match(/\d+/)[0] - 1]

                                if(initialSelectedLesson && initialSelectedLesson.visibility == "published"){
                                    // initialSelectedLesson.tpcCourseId = props.courseData.tpcCourseId
                                    // initialSelectedLesson.categoryId = props.courseData.category[categoryIndex-1].id
                                    initialSelectedLesson.status = "completed"
                                    setSelectedLesson(initialSelectedLesson)
                                    breakLoop++;
                                    console.log(initialSelectedLesson)
                                }
                            }

                        }
                    }

                });

                if(breakLoop == 0){

                    
                    let initialSelectedLesson = findCompletedLesson()
                    initialSelectedLesson.status = "not completed"
                    setSelectedLesson(initialSelectedLesson)

                }
        
            
            }
            else{

                let initialSelectedLesson = findCompletedLesson()
                initialSelectedLesson.status = "not completed"
                setSelectedLesson(initialSelectedLesson)

            }
            

            
      
        }

        Swal.close()

    }, [lessonsTaken])
    

    const findCompletedLesson = () => {

        const courseData = props.courseData

        if (!courseData || !Array.isArray(courseData.category)) {
            console.error("Invalid courseData: 'category' is missing or not an array");
            return null;
        }
    
        // Loop over categories
        for (let categoryIndex = 0; categoryIndex < courseData.category.length; categoryIndex++) {
            const category = courseData.category[categoryIndex];

            // Ensure lessons exist in the main category before searching
            if (Array.isArray(category.lessons)) {
                const completedLesson = category.lessons.find(lesson => lesson.visibility == "published");
                if (completedLesson) {
                    console.log(`Found published lesson in Category ${categoryIndex + 1}, Lesson:`, completedLesson);
                    return completedLesson; // Stop searching
                }
            }
    
            // Ensure subCategories exist and are an array before looping
            if (Array.isArray(category.subCategories)) {
                for (let subIndex = 0; subIndex < category.subCategories.length; subIndex++) {
                    const subCategory = category.subCategories[subIndex];
    
                    // Ensure lessons exist in subCategories
                    if (Array.isArray(subCategory.lessons)) {
                        const completedLesson = subCategory.lessons.find(lesson => lesson.visibility == "published");
                        if (completedLesson) {
                            console.log(`Found published lesson in SubCategory ${subIndex + 1} of Category ${categoryIndex + 1}:`, completedLesson);
                            return completedLesson; // Stop searching
                        }
                    }
                }
            }
    
            
        }
    
        console.log("No published lessons found.");
        return null;
    };


    useEffect(() => {
    
  
        if(props.updateMarkedCompleteLesson.markedcompletelesson.length && typeof props.updateMarkedCompleteLesson.error == "undefined"){
          
            
          Swal.fire({
              title: 'Congrats!',
              text: 'Proceed to the next lesson.',
              icon : 'success',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateMarkedCompleteLessonReset())
            dispatch(fetchMarkedCompleteLesson({handle: props.userDetails.handle}))

            

          })
          
        }
      
        else if(typeof props.updateMarkedCompleteLesson.error != "undefined"){
       
          Swal.fire({
              title:'Failed!',
              text:'Failed to mark as complete lesson!',
              icon :'error',
              timer: 3000
              
            }
          ).then((result) => {
            dispatch(updateMarkedCompleteLessonReset())
            dispatch(fetchMarkedCompleteLesson({handle: props.userDetails.handle}))

          })    
                
        }
      
    }, [props.updateMarkedCompleteLesson])


    const [spreadSheetId, setSpreadSheetId] = useState("")
    
    const [openMap, setOpenMap] = useState({});

    // Toggle a specific table by category ID
    const toggle = (catId) => {
        const key = `cat-${catId}`;
        setOpenMap((prev) => ({
            ...prev,
            [key]: !prev[key], // flip the boolean for this cat
        }));
    };


    // Toggle a SUBCATEGORY by building a unique key like "sub-subcatId"
    const toggleSubcategory = (catId,subcatId) => {
        const key = `sub-${catId}-${subcatId}`;
        setOpenMap((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    return (
    <>  
        <span id="top-div"></span>
        <Row className='mb-3'>
            {/* <Col md="12" className=' mb-3' id="video-section">
                
                <Card className="shadow rounded ">
                    <CardHeader className="border-0 bg-info rounded">
                        <div className='row pl-4'>
                            <div className='col-12 d-flex align-items-center'>
                                <h1 className="display-4 text-white mb-0 text-uppercase  font-weight-bolder">{selectedLesson.title}</h1>
                            </div>
                            <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                <button className='btn btn-default rounded text-lg text-white pl-lg-6 pr-lg-6'>Continue Course</button>
                            </div>
                        </div>
                    </CardHeader>
                    
                </Card>
            </Col> */}
            <Col md="12 " >
                
                {(selectedLesson.video && selectedLesson.video != "undefined" && (selectedLesson.type=="lesson" || typeof selectedLesson.type == "undefined" || selectedLesson.type == "undefined" )) &&
                    <div style={embedContainerStyle} className='embed-container mb-3 shadow'>
                        <iframe src={"//player.vimeo.com/video/"+selectedLesson.video.split('/').slice(-2, -1).pop()+"?h="+selectedLesson.video.split('/').pop().split('?').shift()+"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;"}    frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen className="embed-responsive-item" picture-in-picture style={iframeStyle}></iframe>
                        {/* <iframe src={"//player.vimeo.com/video/"+props.courseData.category[0].lessons[0].video.split('/').slice(-2, -1).pop()+"?h="+props.courseData.category[0].lessons[0].video.split('/').pop().split('?').shift()+"&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;"}    frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen className="embed-responsive-item" picture-in-picture style={iframeStyle}></iframe> */}
                    </div>
                }
                
            </Col>
            <Col md="12 mt-3 mb-3">
                <h1 className="text-darker mb-0  font-weight-bolder lh-130">{selectedLesson.title}</h1>
            </Col>
            
            {(selectedLesson.type=="lesson" || typeof selectedLesson.type == "undefined" || selectedLesson.type == "undefined") ?

            <Col md="12" className='mt-3'>
                
                <Card className="shadow rounded">
                    <CardHeader className="border-0 bg-info rounded pt-3 pb-3">
                        
                        {
                            selectedLesson.status != "completed" ?
                                <div className='row pl-2'>
                                    <div className='col-7 d-flex align-items-center'>
                                        <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">Finished this lesson?</h3>
                                    </div>
                                    <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                        <button onClick={e => handleMarkAsComplete(e, selectedLesson)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'> Mark As Complete</button>
                                    </div>
                                </div>
                            :

                            <div className='row pl-2'>
                                <div className='col-7 d-flex align-items-center'>
                                    <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">Ready To Move On ?</h3>
                                </div>
                                <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                    {

                                        props.courseData.coursePrice ? 
                                            props.courseData.payment != undefined ?
                                                <button onClick={e => handleNextLesson(e)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>Next Lesson</button>
                                            :
                                                <button onClick={e => toggleBuyTpcCourseLessonModal(e)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>Purchase This Course</button>


                                        :
                                        <button onClick={e => handleNextLesson(e)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>Next Lesson</button>


                                    }
                                    
                                </div>
                            </div>

                        }
                    </CardHeader>
                    
                </Card>
            </Col>
            :""}
           
            {typeof selectedLesson.body != "undefined" && selectedLesson.body !== ""?
            (convertToHTML(convertFromRaw(JSON.parse(selectedLesson.body))) != "<p></p>" && convertToHTML(convertFromRaw(JSON.parse(selectedLesson.body))) != "<p> </p>" ) ? 
            <Col md="12 mt-3">
                
                <Card className="shadow">
                    {/* <CardHeader className="border-0 p-0">
                        
                        
                    </CardHeader> */}
                    <CardBody>
                        <h2 className='text-darker font-weight-bolder'>About </h2>
                        <ul>
                            {/* {selectedLesson.body ?
                                convertToHTML(convertFromRaw(JSON.parse(selectedLesson.body))) != "<p></p>" ?
                                <> */}
                                <Editor
                                        className="form-control-alternative"
                                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(selectedLesson.body)))}
                                        toolbarHidden={true}
                                        readOnly
                                        toolbar={{
                                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove', 'history'],
                                            
                                        }}
                                    />
                                {/* </> : "" : ""
                            } */}
                        </ul>
                    </CardBody>
                </Card>
            </Col>
            : "": ""}
            {typeof selectedLesson.files != "undefined" && selectedLesson.files.length != 0 ?       
            <Col md="12 mt-3">
                <Card className="shadow">
                    {/* <CardHeader className="border-0 p-0">
                        
                        
                    </CardHeader> */}
                    <CardBody>
                        <h2 className=' text-darker font-weight-bolder'>Resources</h2>
                        
                        <ul>
                            
                            {selectedLesson.files.map((value) => { return <li key={value.rawFileName}>
                                <a href={"https://storage.googleapis.com/tpcdashboard.appspot.com/files-tpc-courses-lessons/" + value.filenameWithUuid} download={value.rawFileName} className='text-info'>{value.rawFileName}</a></li>
                            })
                            }
                            
                        </ul>

                    </CardBody>
                </Card>
            </Col>
            : ""}
            <Col md="12 mt-3 ">
                <Card className="shadow ">
                    {/* <CardHeader className="border-0 p-0">
                        
                        
                    </CardHeader> */}
                    <CardBody>
                        
                        {props.courseData.instructorHeadshot ? <div className='float-lg-left m-3 d-flex justify-content-center align-items-center'><div className="card-profile-div-sm card-profile-div-md card-profile-div-lg ">
                            <div className="card-profile-image  ">
                            
                                <div className="profile-picture-sm profile-letter-md profile-picture-md profile-picture-lg" style={{backgroundImage: `url("https://storage.googleapis.com/tpcdashboard.appspot.com/img-tpc-courses/` + props.courseData.instructorHeadshot+`")`}}></div>
                            
                            </div>
                        </div> </div>:
                        <div className="card-profile-image float-lg-left mr-md-5 mb-sm-4 d-flex justify-content-sm-center">
                            {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                            
                            <div className="rounded-circle profile-letter-sm-primary profile-picture-md profile-picture-lg profile-letter-lg-primary">{props.courseData.instructorName.trim()[0]}</div>
                            {/* </a> */}
                        </div>}
                        <div style={{minHeight:"180px"}} className='d-flex align-items-center'>
                            <div>
                                <h1 className='text-darker font-weight-bolder mb-0'>{props.courseData.instructorName}</h1>
                                <h3 className='text-info font-weight-bolder ls-15'>INSTRUCTOR</h3>
                                <p className='text-md  lh-150'>{props.courseData.instructorBio}</p>
                            </div>
                        </div>

                    </CardBody>
                </Card>
            </Col>
            
            {
                typeof props.courseData.category != "undefined" ? props.courseData.category.map((category, index) => { 
                   
                    
                    return category.lessons.length != 0 ? category.lessons.some(lesson => lesson.visibility == "published")  ? category.lessons.filter(val => val.type == "live schedule").length ? <><Col md="12" className='mt-5 '>
                        <h2 className=" text-darker mb-0 font-weight-bolder">Live Schedule</h2>


                        {category.lessons.length != 0 ? category.lessons.some(lesson => lesson.visibility == "published")  ? category.lessons.filter(val => val.type == "live schedule").map((lesson, index) => { 

                    
                    return <Col md="12" className='mt-2 mb-2'>

                                <Card className="shadow rounded">
                                    <CardHeader className="border-0 rounded pt-3 pb-3">
                                        <div className='row pl-2 '>
                                            <div className='col-7 d-flex align-items-center'>
                                                <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">{lesson.title}</h3>
                                            </div>
                                            <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                                                
                                            {props.courseData.coursePrice ? 

                                                props.courseData.payment != undefined ?
                                                    <button onClick={e => toggleModalLiveSchedule(e, lesson.spreadSheetId)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>View Schedule</button>
                                                : 
                                                    <button className='btn btn-danger btn-sm' onClick={(e) => toggleBuyTpcCourseLessonModal(e, lesson, category.id)} >
                                                        <i className="fas fa-lock"></i>&nbsp;&nbsp;Unlock lesson
                                                        
                                                    </button> 
                                                :

                                                <button onClick={e => toggleModalLiveSchedule(e, lesson.spreadSheetId)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>View Schedule</button>
                                            }
                                            </div>
                                        </div>
                                    </CardHeader>
                                    
                                </Card>
                            </Col>
                    }) : "" : ""}


                    </Col></> : "" : "" : ""
                   
                
                
                 
                })
            
            : "" }
            
            <Col md="12" className='mt-5 '>
                <h2 className=" text-darker mb-0 font-weight-bolder">Lesson Overview</h2>
                {   typeof props.courseData.category != "undefined" ?  !props.courseData.category.filter(category => 
                   
                   category.lessons.some(lesson => lesson.visibility == "published" && (lesson.type == "lesson" || typeof lesson.type == "undefined" || lesson.type == "undefined") )

                
                    ).length ? <Card className="shadow rounded">
                        
                    <CardHeader className="border-0 rounded pt-3 pb-3">
                        <div className='row pl-2 '>
                            <div className='col-7 d-flex align-items-center'>
                                <h3 className="text-gray mb-0 text-uppercase font-italic font-weight-bolder">No Lessons Available</h3>
                            </div>
                           
                        </div>
                    </CardHeader>
                    
                </Card> :"" : ""
                }
            </Col>
            {
            typeof props.courseData.category != "undefined"
                ? props.courseData.category.map((category, index) => {
                    // let firstLesson = 0;

                    // Decide if we show this category:
                    // (Original ternary chain now placed into a variable for clarity)
                    const showCategory =
                    category.lessons.length != 0
                        ? category.lessons.some(lesson => lesson.visibility == "published")
                        ? category.lessons.filter(
                            val => val.type == "lesson" || typeof val.type == "undefined" || val.type == "undefined"
                            ).length
                            ? true
                            : false
                        : false
                        : false;

                    // Also check if subCategories exist
                    const hasSubCategories =
                    category.subCategories && category.subCategories.length != 0;

                    // If neither category lessons nor subCategories, return empty
                    if (!showCategory && !hasSubCategories) return "";

                    const catKey = `cat-${category.id}`;
                    const isCategoryOpen = !!openMap[catKey];
                    
                    return (
                    <Col md="12" className="mt-3 w-100" key={index}>
                        <Table className="align-items-center table-light table-hover  rounded shadow-lg">
                        <thead className="thead-dark" onClick={() => toggle(category.id)}
                            style={{ cursor: "pointer" }}>
                            <tr>
                            <th scope="col" className="d-flex justify-content-between w-100" style={{ whiteSpace: "normal" }}>
                                <h3 className="text-white font-weight-bolder mb-0 font-italic">
                                <i className="fas fa-play-circle"></i>&nbsp;{category.text}
                                
                                </h3>
                                <span className='text-white font-weight-bolder h3'><i className={isCategoryOpen ? "fas fa-chevron-down" :"fas fa-chevron-up"}></i> </span>
                            </th>
                            </tr>
                        </thead>
                        <Collapse 
                            isOpen={isCategoryOpen}
                            tag="tbody" 
                            transition={{ timeout: 500 }}>
                            {/*
                            -----------------------
                            1) CATEGORY LESSONS
                            -----------------------
                            (Your original code, unchanged)
                            */}
                            {
                            category.lessons.length != 0
                                ? category.lessons.some(lesson => lesson.visibility == "published")
                                ? category.lessons.filter(
                                    val => val.type == "lesson" || typeof val.type == "undefined" ||  val.type == "undefined"
                                    ).map((lesson, index) => {
                                    firstLesson++;

                                    return (
                                        lesson.visibility == "published" && (
                                        <tr key={index + "lesson"}>
                                            <th scope="row" style={{ whiteSpace: "normal" }}>
                                            <div className="row ml-2">
                                                <div className="col-8 d-flex align-items-center">
                                                &nbsp;&nbsp;&nbsp;
                                                
                                                {
                                                    // First lesson logic:
                                                    lesson.id == 1 && firstLesson == 1
                                                    ? typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                        "category" + category.id
                                                        ]?.[
                                                        "lesson" + lesson.id
                                                        ]?.status != "undefined"
                                                        ? (
                                                        <a
                                                            onClick={e =>
                                                            handleSelectLesson(
                                                                e,
                                                                lesson,
                                                                category.id,
                                                                "completed"
                                                            )
                                                            }
                                                            className="mb-0 text-sm text-success"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-check"></i>
                                                            &nbsp;&nbsp;&nbsp;{lesson.title}
                                                        </a>
                                                        )
                                                        : (
                                                        <a
                                                            onClick={e =>
                                                            handleSelectLesson(
                                                                e,
                                                                lesson,
                                                                category.id,
                                                                "not completed"
                                                            )
                                                            }
                                                            className="mb-0 text-sm text-dark test"
                                                            style={{ cursor: "pointer" }}
                                                        >
                                                            <i className="fas fa-play-circle"></i>
                                                            &nbsp;&nbsp;&nbsp;{lesson.title}
                                                        </a>
                                                        )
                                                    : 
                                                    props.courseData.coursePrice
                                                    ? props.courseData.payment != undefined
                                                        ? typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                            "category" + category.id
                                                        ]?.[
                                                            "lesson" + lesson.id
                                                        ]?.status != "undefined"
                                                        ? (
                                                            <a
                                                            onClick={e =>
                                                                handleSelectLesson(
                                                                e,
                                                                lesson,
                                                                category.id,
                                                                "completed"
                                                                )
                                                            }
                                                            className="mb-0 text-sm text-success"
                                                            style={{ cursor: "pointer" }}
                                                            >
                                                            <i className="fas fa-check"></i>
                                                            &nbsp;&nbsp;&nbsp;{lesson.title}
                                                            </a>
                                                        )
                                                        : (
                                                            <a
                                                            onClick={e =>
                                                                handleSelectLesson(
                                                                e,
                                                                lesson,
                                                                category.id,
                                                                "not completed"
                                                                )
                                                            }
                                                            className="mb-0 text-sm text-dark test"
                                                            style={{ cursor: "pointer" }}
                                                            >
                                                            <i className="fas fa-play-circle"></i>
                                                            &nbsp;&nbsp;&nbsp;{lesson.title}
                                                            </a>
                                                        )
                                                        : (
                                                        <a className="mb-0 text-sm">
                                                            <i className="fas fa-play-circle"></i>
                                                            &nbsp;&nbsp;&nbsp;{lesson.title}
                                                        </a>
                                                        )
                                                    : typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                        "category" + category.id
                                                    ]?.[
                                                        "lesson" + lesson.id
                                                    ]?.status != "undefined"
                                                    ? (
                                                        <a
                                                        onClick={e =>
                                                            handleSelectLesson(
                                                            e,
                                                            lesson,
                                                            category.id,
                                                            "completed"
                                                            )
                                                        }
                                                        className="mb-0 text-sm text-success"
                                                        style={{ cursor: "pointer" }}
                                                        >
                                                        <i className="fas fa-check"></i>
                                                        &nbsp;&nbsp;&nbsp;{lesson.title}
                                                        </a>
                                                    )
                                                    : (
                                                        <a
                                                        onClick={e =>
                                                            handleSelectLesson(
                                                            e,
                                                            lesson,
                                                            category.id,
                                                            "not completed"
                                                            )
                                                        }
                                                        className="mb-0 text-sm text-dark test"
                                                        style={{ cursor: "pointer" }}
                                                        >
                                                        <i className="fas fa-play-circle"></i>
                                                        &nbsp;&nbsp;&nbsp;{lesson.title}
                                                        </a>
                                                    )
                                                }
                                                </div>
                                                <div className="col-4 text-right">
                                                {
                                                    // First lesson button logic:
                                                    lesson.id == 1 && firstLesson == 1
                                                    ? (
                                                        <button
                                                        className="btn btn-default btn-sm"
                                                        onClick={e =>
                                                            handleSelectLesson(
                                                            e,
                                                            lesson,
                                                            category.id,
                                                            typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                "category" + category.id
                                                            ]?.[
                                                                "lesson" + lesson.id
                                                            ]?.status != "undefined"
                                                                ? "completed"
                                                                : "not completed"
                                                            )
                                                        }
                                                        >
                                                        {
                                                            typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                            "category" + category.id
                                                            ]?.[
                                                            "lesson" + lesson.id
                                                            ]?.status != "undefined"
                                                            ? "Revisit lesson"
                                                            : "Go to lesson"
                                                        }
                                                        </button>
                                                    )
                                                    : // Paid course button check:
                                                    props.courseData.coursePrice
                                                    ? props.courseData.payment != undefined
                                                        ? (
                                                        <button
                                                            className="btn btn-default btn-sm"
                                                            onClick={e =>
                                                            handleSelectLesson(
                                                                e,
                                                                lesson,
                                                                category.id,
                                                                typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                "category" + category.id
                                                                ]?.[
                                                                "lesson" + lesson.id
                                                                ]?.status != "undefined"
                                                                ? "completed"
                                                                : "not completed"
                                                            )
                                                            }
                                                        >
                                                            {
                                                            typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                "category" + category.id
                                                            ]?.[
                                                                "lesson" + lesson.id
                                                            ]?.status != "undefined"
                                                                ? "Revisit lesson"
                                                                : "Go to lesson"
                                                            }
                                                        </button>
                                                        )
                                                        : (
                                                        <button
                                                            className="btn btn-danger btn-sm"
                                                            onClick={e =>
                                                            toggleBuyTpcCourseLessonModal(
                                                                e,
                                                                lesson,
                                                                category.id
                                                            )
                                                            }
                                                        >
                                                            <i className="fas fa-lock"></i>
                                                            &nbsp;&nbsp;Unlock lesson
                                                        </button>
                                                        )
                                                    : (
                                                        <button
                                                        className="btn btn-default btn-sm"
                                                        onClick={e =>
                                                            handleSelectLesson(
                                                            e,
                                                            lesson,
                                                            category.id,
                                                            typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                "category" + category.id
                                                            ]?.[
                                                                "lesson" + lesson.id
                                                            ]?.status != "undefined"
                                                                ? "completed"
                                                                : "not completed"
                                                            )
                                                        }
                                                        >
                                                        {
                                                            typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                            "category" + category.id
                                                            ]?.[
                                                            "lesson" + lesson.id
                                                            ]?.status != "undefined"
                                                            ? "Revisit lesson"
                                                            : "Go to lesson"
                                                        }
                                                        </button>
                                                    )
                                                }
                                                </div>
                                            </div>
                                            </th>
                                        </tr>
                                        )
                                    );
                                    })
                                : (
                                    !hasSubCategories ? <tr>
                                    <th style={{ whiteSpace: "normal" }}>
                                        <div className="row">
                                        <div className="col-12 d-flex align-items-center">
                                            <p className="mb-0 text-darker font-weight-bold">
                                            &nbsp;&nbsp;&nbsp;
                                            <label className="mb-0">No lesson listed.</label>
                                            </p>
                                        </div>
                                        </div>
                                    </th>
                                    </tr> : ""
                                )
                                : (
                                    !hasSubCategories ? <tr>
                                    <th style={{ whiteSpace: "normal" }}>
                                    <div className="row">
                                        <div className="col-12 d-flex align-items-center">
                                        <p className="mb-0 text-darker font-weight-bold">
                                            &nbsp;&nbsp;&nbsp;
                                            <label className="mb-0">No lesson listed.</label>
                                        </p>
                                        </div>
                                    </div>
                                    </th>
                                </tr> : ""
                                )
                            }

                            {/*
                            -----------------------------
                            2) SUBCATEGORIES WITH PAYMENT
                            -----------------------------
                            */}
                            {
                            hasSubCategories && category.subCategories.map((subCategory, subIndex) => {
                                // let subFirstLesson = 0; // If you want "first lesson" logic for subcats
                                
                                const subKey = `sub-${category.id}-${subCategory.id}`;
                                const isSubcatOpen = !!openMap[subKey];

                                return (
                                <React.Fragment key={subIndex}>
                                    {/* Subcategory header */}
                                    <tr onClick={() => toggleSubcategory(category.id, subCategory.id)}
                                        style={{ cursor: 'pointer' }}>
                                        <th scope="col" style={{ whiteSpace: "normal" }} className='bg-gray-light d-flex justify-content-between'>
                                            <h4 className="text-darker font-weight-bolder mb-0 font-italic ml-4">
                                                <i className="fas fa-folder ml-2"></i>&nbsp;{subCategory.text}
                                            </h4>
                                            <span className='text-darker font-weight-bolder h3'><i className={isSubcatOpen ? "fas fa-chevron-down" :"fas fa-chevron-up"}></i> </span>
                           
                                        </th>
                                    </tr>

                                    {/* Subcategory lessons */}

                                    
                                    {
                                    subCategory.lessons && subCategory.lessons.length != 0
                                        ? subCategory.lessons.some(lesson => lesson.visibility == "published")
                                        ? subCategory.lessons
                                            .filter(val => val.type == "lesson" || typeof val.type == "undefined" || val.type == "undefined")
                                            .map((lesson, subLessonIndex) => {
                                                firstLesson++;
                                            
                                                return lesson.visibility == "published" && (
                                                // <tr key={subLessonIndex + "sublesson"} >
                                                <Collapse key={subLessonIndex + "sublesson"} tag="tr" isOpen={isSubcatOpen}>
                                                    <th scope="row" style={{ whiteSpace: "normal" }}>
                                                    <div className="row ml-5">
                                                        <div className="col-8 d-flex align-items-center">
                                                        &nbsp;&nbsp;&nbsp;
                                                        {/*
                                                            Replicate the EXACT same logic you use above,
                                                            but reference "subcategory" + subCategory.id
                                                            instead of "category" + category.id.
                                                        */}
                                                        
                                                        {
                                                            // If this is the first lesson in the subCategory:
                                                            lesson.id == 1 && firstLesson == 1
                                                            ? typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                "category" + lesson.categoryId
                                                            ][
                                                                "subcategory" + lesson.subCategoryId
                                                                ][
                                                                "lesson" + lesson.id
                                                                ]?.status != "undefined"
                                                                ? (
                                                                <a
                                                                    onClick={e =>
                                                                    handleSelectLesson(
                                                                        e,
                                                                        lesson,
                                                                        category.id,
                                                                        "completed"
                                                                    )
                                                                    }
                                                                    className="mb-0 text-sm text-success"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <i className="fas fa-check"></i>
                                                                    &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                </a>
                                                                )
                                                                : (
                                                                <a
                                                                    onClick={e =>
                                                                    handleSelectLesson(
                                                                        e,
                                                                        lesson,
                                                                        category.id,
                                                                        "not completed"
                                                                    )
                                                                    }
                                                                    className="mb-0 text-sm text-dark test"
                                                                    style={{ cursor: "pointer" }}
                                                                >
                                                                    <i className="fas fa-play-circle"></i>
                                                                    &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                </a>
                                                                )
                                                            : // Paid course check:
                                                            props.courseData.coursePrice
                                                            ? props.courseData.payment != undefined
                                                                ? typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                    "category" + lesson.categoryId
                                                                ]?.[
                                                                    "subcategory" + lesson.subCategoryId
                                                                ]?.[
                                                                    "lesson" + lesson.id
                                                                ]?.status != "undefined"
                                                                ? (
                                                                    <a
                                                                    onClick={e =>
                                                                        handleSelectLesson(
                                                                        e,
                                                                        lesson,
                                                                        category.id,
                                                                        "completed"
                                                                        )
                                                                    }
                                                                    className="mb-0 text-sm text-success"
                                                                    style={{ cursor: "pointer" }}
                                                                    >
                                                                    <i className="fas fa-check"></i>
                                                                    &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                    </a>
                                                                )
                                                                : (
                                                                    <a
                                                                    onClick={e =>
                                                                        handleSelectLesson(
                                                                        e,
                                                                        lesson,
                                                                        category.id,
                                                                        "not completed"
                                                                        )
                                                                    }
                                                                    className="mb-0 text-sm text-dark test"
                                                                    style={{ cursor: "pointer" }}
                                                                    >
                                                                    <i className="fas fa-play-circle"></i>
                                                                    &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                    </a>
                                                                )
                                                                : (
                                                                <a className="mb-0 text-sm">
                                                                    <i className="fas fa-play-circle"></i>
                                                                    &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                </a>
                                                                )
                                                            : typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                "category" + lesson.categoryId
                                                            ]?.[
                                                                "subcategory" + lesson.subCategoryId
                                                            ]?.[
                                                                "lesson" + lesson.id
                                                            ]?.status != "undefined"
                                                            ? (
                                                                <a
                                                                onClick={e =>
                                                                    handleSelectLesson(
                                                                    e,
                                                                    lesson,
                                                                    category.id,
                                                                    "completed"
                                                                    )
                                                                }
                                                                className="mb-0 text-sm text-success"
                                                                style={{ cursor: "pointer" }}
                                                                >
                                                                <i className="fas fa-check"></i>
                                                                &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                </a>
                                                            )
                                                            : (
                                                                <a
                                                                onClick={e =>
                                                                    handleSelectLesson(
                                                                    e,
                                                                    lesson,
                                                                    category.id,
                                                                    "not completed"
                                                                    )
                                                                }
                                                                className="mb-0 text-sm text-dark test"
                                                                style={{ cursor: "pointer" }}
                                                                >
                                                                <i className="fas fa-play-circle"></i>
                                                                &nbsp;&nbsp;&nbsp;{lesson.title}
                                                                </a>
                                                            )
                                                        }
                                                        </div>

                                                        <div className="col-4 text-right">
                                                        {
                                                            // "Go to lesson" or "Revisit" button:
                                                            lesson.id == 1 && firstLesson == 1
                                                            ? (
                                                                <button
                                                                className="btn btn-default btn-sm"
                                                                onClick={e =>
                                                                    handleSelectLesson(
                                                                    e,
                                                                    lesson,
                                                                    category.id,
                                                                    typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                        "category" + lesson.categoryId
                                                                    ]?.[
                                                                        "subcategory" + lesson.subCategoryId
                                                                    ]?.[
                                                                        "lesson" + lesson.id
                                                                    ]?.status != "undefined"
                                                                        ? "completed"
                                                                        : "not completed"
                                                                    )
                                                                }
                                                                >
                                                                {
                                                                    typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                        "category" + lesson.categoryId
                                                                    ]?.[
                                                                    "subcategory" + lesson.subCategoryId
                                                                    ]?.[
                                                                    "lesson" + lesson.id
                                                                    ]?.status != "undefined"
                                                                    ? "Revisit lesson"
                                                                    : "Go to lesson"
                                                                }
                                                                </button>
                                                            )
                                                            : // Paid or free subCategory logic
                                                            props.courseData.coursePrice
                                                            ? props.courseData.payment != undefined
                                                                ? (
                                                                <button
                                                                    className="btn btn-default btn-sm"
                                                                    onClick={e =>
                                                                    handleSelectLesson(
                                                                        e,
                                                                        lesson,
                                                                        category.id,
                                                                        typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                            "category" + lesson.categoryId
                                                                        ]?.[
                                                                        "subcategory" + lesson.subCategoryId
                                                                        ]?.[
                                                                        "lesson" + lesson.id
                                                                        ]?.status != "undefined"
                                                                        ? "completed"
                                                                        : "not completed"
                                                                    )
                                                                    }
                                                                >
                                                                    {
                                                                    typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                        "category" + lesson.categoryId
                                                                    ]?.[
                                                                        "subcategory" + lesson.subCategoryId
                                                                    ]?.[
                                                                        "lesson" + lesson.id
                                                                    ]?.status != "undefined"
                                                                        ? "Revisit lesson"
                                                                        : "Go to lesson"
                                                                    }
                                                                </button>
                                                                )
                                                                : (
                                                                <button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={e =>
                                                                    toggleBuyTpcCourseLessonModal(
                                                                        e,
                                                                        lesson,
                                                                        subCategory.id
                                                                    )
                                                                    }
                                                                >
                                                                    <i className="fas fa-lock"></i>
                                                                    &nbsp;&nbsp;Unlock lesson
                                                                </button>
                                                                )
                                                            : (
                                                                <button
                                                                className="btn btn-default btn-sm"
                                                                onClick={e =>
                                                                    handleSelectLesson(
                                                                    e,
                                                                    lesson,
                                                                    category.id,
                                                                    typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                        "category" + lesson.categoryId
                                                                    ]?.[
                                                                        "subcategory" + lesson.subCategoryId
                                                                    ]?.[
                                                                        "lesson" + lesson.id
                                                                    ]?.status != "undefined"
                                                                        ? "completed"
                                                                        : "not completed"
                                                                    )
                                                                }
                                                                >
                                                                {
                                                                    typeof lessonsTaken?.[0]?.[props.courseData.tpcCourseId]?.[
                                                                        "category" + lesson.categoryId
                                                                    ]?.[
                                                                    "subcategory" + lesson.subCategoryId
                                                                    ]?.[
                                                                    "lesson" + lesson.id
                                                                    ]?.status != "undefined"
                                                                    ? "Revisit lesson"
                                                                    : "Go to lesson"
                                                                }
                                                                </button>
                                                            )
                                                        }
                                                        </div>
                                                    </div>
                                                    </th>
                                                </Collapse>
                                                );
                                            })
                                        : (
                                            !category.lessons.length ?  <Collapse tag="tr" isOpen={isSubcatOpen}>
                                            <th style={{ whiteSpace: "normal" }}>
                                                <div className="row">
                                                <div className="col-12 d-flex align-items-center">
                                                    <p className="mb-0 text-darker font-weight-bold">
                                                    &nbsp;&nbsp;&nbsp;
                                                    <label className="mb-0">No lesson listed.</label>
                                                    </p>
                                                </div>
                                                </div>
                                            </th>
                                            </Collapse> : ""
                                        )
                                        : null
                                    }
                               
                                </React.Fragment>
                                );
                            })
                            }
                        </Collapse>
                        </Table>
                    </Col>
                    );
                })
                : ""
            }

            {(selectedLesson.type=="lesson" || typeof selectedLesson.type == "undefined" || selectedLesson.type == "undefined") ?

            <Col md="12" className='mt-2 mb-5'>
                
                <Card className="shadow rounded">
                    <CardHeader className="border-0 bg-info rounded pt-3 pb-3">
                        <div className='row pl-2 '>
                            <div className='col-7 d-flex align-items-center'>
                                <h3 className="text-darker mb-0 text-uppercase font-italic font-weight-bolder">Ready to start ?</h3>
                            </div>
                            <div className='col-5 text-right d-flex align-content-center justify-content-end'>
                            {
                                props.courseData.coursePrice ? 
                                    props.courseData.payment != undefined ?
                                        
                                            <button className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5' onClick={e => handleContinueCourse(e)}>Continue Course</button>
                                       
                                    :
                                        <button onClick={e => toggleBuyTpcCourseLessonModal(e)} className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5'>Purchase This Course</button>
                                :
                                
                                <button className='text-nowrap btn btn-default btn-sm rounded text-sm text-white pl-xl-7 pr-xl-7 pl-lg-6 pr-lg-6 pl-md-4 pr-md-4 pl-sm-5 pr-sm-5' onClick={e => handleContinueCourse(e)}>Continue Course</button>
                                
                                
                            }
                            </div>
                        </div>
                    </CardHeader>
                    
                </Card>
            </Col>
             :""}
        </Row>

        <BuyTpcCourseModal
            course={props.courseData}
            modalBuyTpcCourseLessonModal={modalBuyTpcCourseLessonModal}
            toggleBuyTpcCourseLessonModal={toggleBuyTpcCourseLessonModal}
        />
        
        <Modal className="modal-dialog-centered  modal-xl modal-dialog-scrollable" style={{maxWidth: '2000px', width: '98%'}} isOpen={modalLiveSchedule} toggle={toggleModalLiveSchedule} >
            <ModalHeader toggle={toggleModalLiveSchedule} className='d-flex align-items-center'>Live Schedules</ModalHeader>
            <ModalBody className='border'>
                <LiveSchedule spreadSheetId={spreadSheetId}></LiveSchedule>
            </ModalBody>
            <ModalFooter>
                <Button color="info" onClick={toggleModalLiveSchedule}>Close</Button>
            </ModalFooter>
        </Modal>
        
    </>
  )
}

const mapStateToProps = state  => {
    return {
        userDetails : state.loginAuth.userDetails,
        updateMarkedCompleteLesson : state.updateMarkedCompleteLesson,
        fetchMarkedCompleteLesson : state.fetchMarkedCompleteLesson,
        buyTutoringHoursModalStatus: state.buyTutoringHoursModalStatus,
       
        
    }
      
}

export default connect(mapStateToProps)(TpcCoursesLessons)
