import React, { Fragment,useState, useEffect,useMemo,useCallback } from "react";
import {
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  CardText,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Pagination,
  FormGroup,
  PaginationItem,
  PaginationLink,
  CardFooter,
  Button,
  Table,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
ListGroup,
  ListGroupItem,
  Spinner,
  Alert,
  UncontrolledTooltip,
  Label
  
} from "reactstrap";
import  Select  from 'react-select';
import { customDropdown } from 'util/reactCustomStyles';
import { Calendar,momentLocalizer, Views } from 'react-big-calendar'
import Header from "components/Headers/Header";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import classnames from "classnames";
import moment from "moment";
import 'moment-timezone'; 
import { customDropdownWithBorder } from "util/reactCustomStyles";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {  createRequestTutor,createRequestTutorReset,fetchCoaches,fetchAvailableSchedule,fetchSchedule  ,fetchStudentSchedule } from "redux/ScheduleTutor/ScheduleTutorActions";
import ScheduleItem from "components/ScheduleComponents/ScheduleItem";
import RequestsTable from "components/ScheduleComponents/RequestsTable";
import { buyTutoringHours } from "redux/Payments/PaymentsActions";
import BuyTutoringHoursModal from "components/Modal/BuyTutoringHoursModal";
import Plans from './Plans';
import { getUserData } from "redux/UserAuthentication/LoginActions";
import {
  fetchUserDetails,
} from "redux/ManageUsers/ManageUsersAction";

const ScheduleTutor = (props) => {

  const [userInfo,setUserInfo] = useState([]) 
  const [uastudent, setUaStudent ] = useState();

  useEffect(() => {
    setUserInfo(props.userDetails)
    if(props.userDetails.ultimateAdvisingStudent === true  ){
      // console.log(props.userDetails)
      setUaStudent(true)
    }
  }, [props.userDetails]);

  const dispatch = useDispatch();
  const [events,setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState()

  const customStyles = {
      menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
      menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const modalBuyTutoringHoursModal = props.buyTutoringHoursModalStatus.status;
  const toggleBuyTutoringHoursModal = () => {
    dispatch(buyTutoringHours(!props.buyTutoringHoursModalStatus.status));
  };


  //Tabs
  const mainopt = ["Coaches Schedule", "My Requests", "History"];
  const [mainActiveTab, setMainActiveTab] = useState("Coaches Schedule");

  const toggleMain = (tab) => {
    if (mainActiveTab !== tab) setMainActiveTab(tab);
  };

  const historyopt = ["Pending", "Approved", "Disapproved", "Canceled"];
  const [activeTab, setActiveTab] = useState("Pending");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [schedActiveTab, setSchedActiveTab] = useState("1");


  //Modal Behaviour
  const [modal, setModal] = useState(false);
  const modalToggle = () => setModal(!modal);
  const [backdrop, setBackdrop] = useState(true);

  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const toggleNested = () => {
      setNestedModal(!nestedModal);
      setCloseAll(false);
  };


    //Set local timezone
    const  myTimeZone  = Intl.DateTimeFormat().resolvedOptions();
    moment.tz.setDefault(myTimeZone.timeZone)
    const localizer = momentLocalizer(moment) 

    //Converts the datetime UTC to local time
    const convertToLocalTime = (date) => {
        return new Date(date)
    }

    const {defaultDate, views} = useMemo(() => ({
        defaultDate: new Date(),
        // views: [Views.MONTH, Views.WEEK, Views.DAY ],
    }), [])

    const convertToTime = (time) =>{
        return moment(time).format('LT'); 
    }
    const convertToDate = (date) =>{
        return moment(date).format('LL'); 
    }

    const handleSelectSlot = useCallback((slotinfo) => {
        // console.log(JSON.stringify(slotinfo))
        window.prompt(JSON.stringify(slotinfo))
        
    }, [])

    const onSelectEvent = useCallback((event) => {
      if( userInfo.tutorHours == "0"){
        toggleBuyTutoringHoursModal()    
      }else{
        setSelectedEvent(event)
        setModal(true)
      }
    }, [userInfo])

  const [coach, setCoach] = useState();
	const [coachOption, setCoachOption] = useState([]);
    const selectACoach = (e) => {
		setCoach(e.value)
        if(e.value == ""){
          dispatch(fetchAvailableSchedule())
        }else{
          dispatch(fetchSchedule({handle: e.value}))
        }
	};

    useEffect(() => {
        if (props.fetchCoaches.fetchcoaches.length) {
            const coachesOption = props.fetchCoaches.fetchcoaches[0];
            coachesOption.unshift({ value: "", label: "All Coaches" });
            setCoachOption(coachesOption);
        }
    }, [props.fetchCoaches]);

    useEffect(() => {
        dispatch( fetchAvailableSchedule());
        dispatch(fetchCoaches());
    }, []);

    const [initialSched, setInitialSched] = useState([])

    const convertThedate = (date) => {
      return moment(date).utcOffset(0, false).format('ll');
    }

    useEffect(() => {
        if (props.fetchAvailableSchedule.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            Swal.close();
        }

        if (props.fetchAvailableSchedule.fetchavailableschedule.length) {
            let iterateCollectionOfSched = []
            
            props.fetchAvailableSchedule.fetchavailableschedule[0].map((sched, sindex)=>{
                let startDate = convertThedate(sched.startdate)
                let endDate = convertThedate(sched.enddate)
                let dates = [];
       
                for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
                    
                    // if(sched.days.includes(m.format('dddd'))){
                      
                        let startDateFormatted = m
                        let endDateFormatted = m


                        let startTime = convertTimeToLocal(sched.starttime) 
                        let endTime = convertTimeToLocal(sched.endtime) 
                        
                        if(moment(endTime).format('HH:mm') <= moment(startTime).format('HH:mm')){
                            endTime.add(1,'days')
                            endDateFormatted = moment(endDateFormatted).add(1,'days')
                        }

                        let duration = moment.duration(endTime.diff(startTime));
                        let diff = duration.hours();
                        let getDAtes = [];

                      

                        if(moment(moment(m).format('L')).isSameOrAfter(moment(moment().format('L')))){
                         
                          dates.push({
                              scheduleid: sched.schedid,
                              day: convertToDate(startDateFormatted),
                              date: convertToDate(startDateFormatted),
                              title: sched.coach.name,
                              start: new Date(convertToDate(startDateFormatted)+' '+moment(startTime).format('h:mm A')),
                              end: new Date(convertToDate(endDateFormatted)+' '+moment(endTime).format('h:mm A')),
                              startLabel:  new Date(convertToDate(startDateFormatted)+' '+moment(startTime).format('h:mm A')),
                              endLabel: new Date(convertToDate(endDateFormatted)+' '+moment(endTime).format('h:mm A')),
                              details: sched.coach,
                              requests: sched.requests
                          })

                        }

                        // for (let i = 0; diff >= i; i++) {
                        //     let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
                        //     getDAtes.push(
                        //         {
                        //             // id: sched.schedid+i,
                        //             desc: sched.description,
                        //             title: sched.coach.name,
                        //             details: sched.coach,
                        //             date: convertToDate(m),
                        //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
                        //             end: new Date(convertToDate(m)+' '+moment(result).add(30, 'minutes').format('h:mm A')),
                        //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
                        //             endLabel: convertToDate(m)+' '+moment(result).add(30, 'minutes').format('h:mm A')
                        //         }
                        //     )
                        // }
                        
                        // dates.push({
                        //     day: convertToDate(m),
                        //     item : getDAtes
                        // })
                    // } 
                    
                }
                
                iterateCollectionOfSched.push(dates)
              
            })
            setInitialSched(iterateCollectionOfSched)
        }
    }, [props.fetchAvailableSchedule]);

     useEffect(() => {
        if (props.fetchSchedule.loading) {
            Swal.fire({
                allowOutsideClick: false,
                didOpen: () => {
                Swal.showLoading();
                },
            });
        } else {
            Swal.close();
        }

        if (props.fetchSchedule.schedule.length) {
            let iterateCollectionOfSched = []
            props.fetchSchedule.schedule.map((sched, sindex)=>{
                let startDate = convertThedate(sched.startdate)
                let endDate = convertThedate(sched.enddate)
                let dates = [];
                for (var m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
                    
                    // if(sched.days.includes(m.format('dddd'))){
                       
                        let startTime = convertTimeToLocal(sched.starttime) 
                        let endTime = convertTimeToLocal(sched.endtime) 

                        let startDateFormatted = m
                        let endDateFormatted = m
                     
                        if(moment(endTime).format('HH:mm') < moment(startTime).format('HH:mm')){
                            endTime.add(1,'days')
                            endDateFormatted = moment(endDateFormatted).add(1,'days')
                            
                        }

                        let duration = moment.duration(endTime.diff(startTime));
                        let diff = duration.hours();
                        let getDAtes = [];

                        if(moment(moment(m).format('L')).isSameOrAfter(moment().format('L'))){

                          dates.push({
                              scheduleid: sched.schedid,
                              day: convertToDate(startDateFormatted),
                              date: convertToDate(startDateFormatted),
                              title: sched.coach.name,
                              start: new Date(convertToDate(startDateFormatted)+' '+moment(startTime).format('h:mm A')),
                              end: new Date(convertToDate(endDateFormatted)+' '+moment(endTime).format('h:mm A')),
                              startLabel:  new Date(convertToDate(startDateFormatted)+' '+moment(startTime).format('h:mm A')),
                              endLabel: new Date(convertToDate(endDateFormatted)+' '+moment(endTime).format('h:mm A')),
                              details: sched.coach,
                              requests: sched.requests
                          })
                      }

                       
                        // for (let i = 0; diff >= i; i++) {
                        //      let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')
                        //     getDAtes.push(
                        //         {
                        //             // id: sched.schedid+i,
                        //             desc: sched.description,
                        //             title: sched.coach.name,
                        //             details: sched.coach,
                        //             date: convertToDate(m),
                        //             start: new Date(convertToDate(m)+' '+result.format('h:mm A')),
                        //             end: new Date(convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')),
                        //             startLabel: convertToDate(m)+' '+result.format('h:mm A'),
                        //             endLabel: convertToDate(m)+' '+moment(result).add(30,'minutes').format('h:mm A')
                        //         }
                        //     )
                        // }
                        
                        // dates.push({
                        //     day: convertToDate(m),
                        //     item : getDAtes
                        // })
                    } 
                    
                // }

                // console.log(dates)
                iterateCollectionOfSched.push(dates)
              
            })

            // console.log(iterateCollectionOfSched)
            setInitialSched(iterateCollectionOfSched)
        }else{
            // Swal.fire({
            //    title: "No schedule found!",
            //    text: "This coach has no schedule yet!",
            //    type: "warning"
            // });
            // setEvents([])
        }
        
    }, [props.fetchSchedule]);

    useEffect(() => {
      if(initialSched.length){
        // console.log(initialSched)
          let collectEvents = []
          initialSched.map((coaches, ndx) => {
              coaches.map((items, i) => {
                  // items.map((scheds, index) => {
                      collectEvents.push(items)
                  // })
              })
              
          })
          setEvents(collectEvents)
      }
    }, [initialSched]);

    const [selectedType, selectType] = useState("1HPT");
    const requestType = [
        {
            label: '1hr Premium Hour',
            value: '1HPT',
        },
        // {
        //     label: '30min DAT Tutoring',
        //     value: '30MPT',
        // },
        {
            label: '30min Free Tutoring',
            value: '30FT',
        },
    ] 
    
    const handleSelectType = (e,type) => {
      selectType(type)
    }
    useEffect(() => {
      if(selectedEvent){
        // console.log(selectedEvent)
      
        let startTime = moment(moment(selectedEvent.start), "HH:mm")
        let endTime = moment(moment(selectedEvent.end), "HH:mm")

        let duration = moment.duration(endTime.diff(startTime));
        let diff = duration.hours() == 0?24 : duration.hours() ;
        let getSchedPerHour = [];
        let getSchedPerMinute = [];
        // console.log(selectedEvent.scheduleid)
        // console.log(selectedEvent.requests)

        //Per Hour
        for (let i = 0; diff > i; i++) {
            let result =  i == 0 ? moment(startTime) : moment(startTime).add(i, 'hours')
            getSchedPerHour.push(
                {
                  coachSchedId: selectedEvent.scheduleid, 
                  start: result.format('LLL'),
                  end: moment(result).add(1,'hours').format('LLL'), 
                }
            )
        }

        //Per 30min
        while (startTime < endTime) {
            getSchedPerMinute.push(
                {
                  coachSchedId: selectedEvent.scheduleid, 
                  start: startTime.format('LLL'),
                  end: startTime.add(30,'minutes').format('LLL'), 
                }
            )
        }
        let filterPerHour = []
        getSchedPerHour.map((hsched, i) => {
          if(selectedEvent.requests.length){
            selectedEvent.requests.forEach(request => {
              if(hsched.coachSchedId == request.coachScheduleID){
                if(convertTimeToUTC(hsched.start).format() == request.starttime && convertTimeToUTC(hsched.end).format() == request.endtime ){
                  hsched.status = "unavailable"
                } 
              }
              
            });
            filterPerHour.push(hsched)
          }else{
            filterPerHour.push(hsched)
          }
        })

        let filterPerMinute = []
        getSchedPerMinute.map((msched, im) => {
          if(selectedEvent.requests.length){
            selectedEvent.requests.forEach(request => {
              if(msched.coachSchedId == request.coachScheduleID){
                if(convertTimeToUTC(msched.start).format() == request.starttime ){
                  msched.status = "unavailable"
                }
                if(convertTimeToUTC(msched.end).format() == request.endtime ){
                  msched.status = "unavailable"
                }
              }
              
            });
            filterPerMinute.push(msched)
          }else{
             filterPerMinute.push(msched)
          }
        })
        //  for (let i = 0; diff >= i; i++) {
        //     let result =  i == 0 ? moment(startTime).add(i, 'minutes') : moment(startTime).add(i, 'hours').subtract(30,'minutes')

          
        //     getSchedPerMinute.push(
        //         {
        //             start: result.format('LLL'),
        //             end: moment(result).add(30,'minutes').format('LLL'), 
        //         }
        //     )
        // }
        // console.log(filterPerHour)
        setSchedulePerHour(filterPerHour)
        setSchedulePerMinute(filterPerMinute)
      
      }
    }, [selectedEvent]);

    // const [studentRequests, setStudentRequests] = useState([])
    // useEffect(() => {
    //   if(props.fetchStudentSchedule.fetchstudentschedule.length){
    //     setStudentRequests(props.fetchStudentSchedule.fetchstudentschedule)
    //   }
    
    // }, []);

    const [schedulePerHour,setSchedulePerHour] = useState([])
    const [schedulePerMinute,setSchedulePerMinute] = useState([])
    const [selectedSchedule,setSelectedSchedule ] = useState()

    const convertTimeToUTC = (time) => {
        return moment(moment(time).utcOffset(0, false), "HH:mm")
    }

    const convertTimeToLocal = (time) => {
        return moment(moment(time).utcOffset(0, true), "HH:mm")
    }

    const handleSelectSchedule = (data) =>{
        setNestedModal(!nestedModal);
        setSelectedSchedule(data)
    }

    const [sessionDescription, setSessionDescription] = useState("")

    const handleRequestTutorOnClick = (e) => {
        e.preventDefault();
        Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
            Swal.showLoading()
            }
        });
        setNestedModal(!nestedModal);
        setModal(!modal)
        
        const doc = {
            starttime: convertTimeToUTC(selectedSchedule.start).format(),
            endtime: convertTimeToUTC(selectedSchedule.end).format(),
            status: "pending",
            codetype: selectedType,
            category: "schedule tutor",
            requestType: selectedType == "30FT" ? "free" : "premium", 
            duration: selectedType == "1HPT" ? "1hr" : "30min",
            studentHandle: userInfo.handle,
            coachScheduleID: selectedEvent.scheduleid,
            coachHandle: selectedEvent.details.handle,
            sessionDescription: sessionDescription,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
        };

        const coachDetails = {
          coachEmail : selectedEvent.details.email,
          coachName : selectedEvent.details.name,
          date:   moment(selectedSchedule.start).format('ll') != moment(selectedSchedule.end).format('ll') ? moment(selectedSchedule.start).format('ll')+" - "+moment(selectedSchedule.end).format('ll'):  moment(selectedSchedule.start).format('ll'),
          time:  moment(selectedSchedule.start).format('h:mm A')+ "-"+ moment(selectedSchedule.end).format('h:mm A') 
        }

      dispatch(createRequestTutor({ handle: userInfo, docs: doc, coach: coachDetails }));
    };

    useEffect(() => {
      if(props.createRequestTutor.loading){
        Swal.fire({
            allowOutsideClick : false,
            didOpen: () => {
                Swal.showLoading()
            }
        });
      }
      else{
          Swal.close()
      }

      if(props.createRequestTutor.createrequesttutor.length){
        Swal.fire({
            title: "Success",
            text: "Sucessfully submitted a request",
            icon: "success",
        });

        setSessionDescription("")
        dispatch(fetchAvailableSchedule())
        dispatch(fetchUserDetails({ userId: userInfo.userId }));

      }

    }, [props.createRequestTutor])

    useEffect(() => {
      if(props.fetchUserDetails.userdetails.length){
        setUserInfo(props.fetchUserDetails.userdetails[0])
      }
    }, [props.fetchUserDetails])

    

  return (
    <>
      <Header></Header>

      <Container className="mt--7" fluid>
        <Row>
          <Col className="mt-3 mb-4 d-flex justify-content-center">
            <Nav fill pills>
              {mainopt.length > 0
                ? mainopt.map((c, i) => {
                    return (
                      <NavItem className="ml-1" key={i}>
                        <NavLink
                          key={c}
                          className={classnames({
                            active: mainActiveTab === c,
                          })}
                          onClick={() => {
                            toggleMain(c);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {c}
                        </NavLink>
                      </NavItem>
                    );
                  })
                : ""}
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col lg="12" className="mt-3 p-2">
              <TabContent activeTab={mainActiveTab}>
                 {mainopt.map((mc, i) => (
                  <TabPane tabId={mc} key={i}>
                    {
                    mc == "Coaches Schedule" ? 
                      <Row className="mt-4">
                        <Col lg="12" >
                          <Card className="shadow">
                              <CardHeader className="border-1">
                                  <Row>
                                    <Col log="6" className="mb-2">
                                      <div >
                                        <Button color="primary" size="sm" onClick={toggleBuyTutoringHoursModal} className="mb-1" >Buy Premium Hours</Button> 
                                        {
                                          userInfo.tutorHours != 0 ?
                                            <h4 className="text-yellow ">You have {userInfo.tutorHours + ' Premium Hours Remaining' }</h4> 
                                          : <h4 className="text-yellow ">You have {userInfo.tutorHours + ' Premium Hours ' }</h4> 
                                        }
                                        
                                      </div>
                                      {
                                        uastudent ? 
                                        <h5 className="h3 mb-0 text-yellow">  
                                        30 minutes Free Per Week <span className="fa fa-question-circle text-default" id={"freeinfo"} ></span>
                                          <UncontrolledTooltip placement="right" target={"freeinfo"}>
                                            Only choose the 30-minute bonus session if it has already been
                                              approved. These sessions should be reserved for quarterly
                                              check-ins or for general coaching when you have exhausted your
                                              premium hours.
                                          </UncontrolledTooltip>
                                      </h5>
                                        : ''
                                      }

                                    </Col>
                                      <Col lg="6">
                                          <FormGroup>
                                          <Label >FILTER BY COACH</Label>
                                          <Select
                                              
                                              options={coachOption}
                                              menuPortalTarget={document.body} 
                                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                              // classNamePrefix="select"
                                              // placeholder="Select a Coach.."
                                              onChange={selectACoach}
                                              formatOptionLabel={(v) => (
                                                  <div className="d-flex ">
                                                      {v.value ? (
                                                      v.image ? (
                                                          <img
                                                          className="rounded-circle  mr-1 "
                                                          style={{ width: "25px", height: "25px" }}
                                                          src={
                                                              "https://storage.googleapis.com/tpcdashboard.appspot.com/profile-picture/" +
                                                              v.image
                                                          }
                                                          alt={v.label}
                                                          />
                                                      ) : (
                                                          <div
                                                          className="rounded-circle profile-letter-all-users mr-1 "
                                                          style={{ width: "25px", height: "25px" }}
                                                          >
                                                          {v.label ? v.label.trim()[0] : ""}
                                                          </div>
                                                      )
                                                      ) : (
                                                      ""
                                                      )}

                                                      <span>{v.label}</span>
                                                  </div>
                                              )}
                                          />   
                                          </FormGroup>
                                      </Col>
                                  </Row>
                                  
                              </CardHeader>
                              <CardBody>
                                      <Fragment>
                                          <div className="" style={{"height":"800px"}}>
                                              <Calendar
                                                  views={views}
                                                  defaultDate={defaultDate}
                                                  localizer={localizer}
                                                  events={events}
                                                  startAccessor="start"
                                                  endAccessor="end"
                                                  showMultiDayTimes
                                                  onSelectEvent={onSelectEvent}
                                                  // onSelectSlot={handleSelectSlot}
                                                  // selectable
                                                  step={60}
                                                  messages={{agenda: 'Schedules', event: 'Coaches'}}
                                              />
                                          </div>
                                      </Fragment>
                              </CardBody>
                              <CardFooter></CardFooter>
                          </Card>
                        </Col>
                      </Row>
                    :  mc == "My Requests" ?
                     <RequestsTable />
                    : 
                    <>
                      <Row>
                        <Col className=" d-flex justify-content-center">
                          <Nav tabs>
                            {historyopt.length > 0
                              ? historyopt.map((c, i) => {
                                  return (
                                    <NavItem className="ml-1" key={i}>
                                      <NavLink
                                        key={c}
                                        className={classnames({
                                          active: activeTab === c,
                                        })}
                                        onClick={() => {
                                          toggle(c);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                      >
                                        {c}
                                      </NavLink>
                                    </NavItem>
                                  );
                                })
                              : ""}
                          </Nav>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12" className="mt-3 p-2">
                          <TabContent activeTab={activeTab}>
                            {historyopt.map((c, i) => {
                              return (
                                <TabPane tabId={c} key={i}>
                                  {c == "Pending" ? (
                                    <ScheduleItem
                                      status="pending"
                                      color="warning"
                                    />
                                  ) : c == "Approved" ? (
                                    <ScheduleItem
                                      status="approved"
                                      color="success"
                                    />
                                  ) : c == "Canceled" ? (
                                    <ScheduleItem
                                      status="canceled"
                                      color="danger"
                                    />
                                  ) : (
                                    <ScheduleItem
                                      status="disapproved"
                                      color="danger"
                                    />
                                  )}
                                </TabPane>
                              );
                            })}
                          </TabContent>
                        </Col>
                      </Row>
                    </>
                    }
                  </TabPane>

                 ))
                 }

              </TabContent>
          </Col>
        </Row>
       
        <Modal
          isOpen={modal}
          toggle={modalToggle}
          backdrop={backdrop}
          size="lg"
          centered
        >
            <ModalHeader toggle={modalToggle}>
                {selectedEvent ?  selectedEvent.title : " "}
            </ModalHeader>
            <ModalBody className="pt-1">
                { 
                    selectedEvent ?
                  
                        <Card>
                            <CardBody>
                            <Row>
                                <Col lg="6">
                                    <Media className="align-items-center mb-3">
                                        {
                                            typeof selectedEvent.img != "undefined" ? (
                                                <div className="media">
                                                    <img 
                                                        
                                                        src={selectedEvent.img}
                                                        className="mr-3" 
                                                        alt="..." 
                                                        style={{
                                                            width: "60px",
                                                            height: "60px", 
                                                        }}
                                                    />
                                                    <div className="media-body">
                                                        <h5 className="mt-0">{selectedEvent.title}</h5>
                                                        <small></small>
                                                    
                                                    </div>
                                                </div>
                                        
                                            ) : 
                                            (
                                                <div className="media">
                                                    <div className="rounded-circle profile-letter-all-users mr-2" 
                                                        style={{
                                                        width: "60px",
                                                        height: "60px",
                                                            fontSize: "16px"
                                                            }}>
                                                            {selectedEvent.title !== undefined ? selectedEvent.title.trim()[0].toUpperCase() : "1" }
                                                    </div>
                                                    <div className="media-body">
                                                        <h5 className="my-0">{selectedEvent.title}</h5>
                                                        <small>{selectedEvent.details.email}</small>
                                                    
                                                    </div>
                                                </div>
                                            )
                                        }
                                        
                                    </Media>
                                    <p className="text-dark mb-1">Date: {moment(selectedEvent.startLabel).format('ll') != moment(selectedEvent.endLabel).format('ll') ? moment(selectedEvent.startLabel).format('ll')+" - "+moment(selectedEvent.endLabel).format('ll'):  moment(selectedEvent.startLabel).format('ll') }</p>
                                    <p className="text-dark">Time: {moment(selectedEvent.startLabel).format('h:mm A')+" - "+moment(selectedEvent.endLabel).format('h:mm A')}</p>

                                    <FormGroup tag="fieldset" >
                                        <h3 >Select type of hour to use</h3>

                                        {
                                            requestType.map((t,ii)=>{
                                            return <FormGroup check key={ii}>
                                                <Label check>
                                                    <Input 
                                                        type="radio" 
                                                        name="radio2" 
                                                        value={t.value}
                                                        checked={t.value === selectedType}
                                                        onChange={(e) => handleSelectType(e,t.value)}
                                                    />
                                                    {
                                                        t.value == "30FT" ?
                                                        
                                                        <>
                                                        {t.label + " "}  
                                                        <span className="fa fa-info-circle" id={"freeinfo"} ></span>
                                                        <UncontrolledTooltip placement="right" target={"freeinfo"}>
                                                            Only choose the 30-minute bonus session if it has already been
                                                            approved. These sessions should be reserved for quarterly
                                                            check-ins or for general coaching when you have exhausted your
                                                            premium hours.
                                                        </UncontrolledTooltip>
                                                        </>
                                                        :
                                                        ' '+t.label 
                                                    }
                                                </Label>
                                            </FormGroup>
                                            })
                                        }

                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                     <h3 >Select schedule</h3>

                                    {
                                        selectedType == "1HPT"
                                            ?
                                            schedulePerHour ? 
                                                schedulePerHour.map((v,i) => (
                                                  
                                                        <Button key={i} size="sm" color="primary"  disabled={!v.status ? false : true} className="m-2"  onClick={(e) => handleSelectSchedule(v)} title={!v.status ? "Available" : "Unavailable"} >
                                                            {
                                                                moment(v.start).format('h:mm A')+' - '+moment(v.end).format('h:mm A')
                                                            }
                                                        </Button>
                                                
                                                ))

                                            : ""
                                        :  schedulePerMinute ? 
                                            schedulePerMinute.map((v,i) => (
                                               
                                                    <Button key={i} color="warning" size="sm" className="m-2" disabled={!v.status ? false : true}  onClick={(e) => handleSelectSchedule(v)} title={!v.status ? "Available" : "Unavailable"}>
                                                        {
                                                            moment(v.start).format('h:mm A')+' - '+moment(v.end).format('h:mm A')
                                                        }
                                                    </Button>
                                               
                                            ))

                                        : ""
                                    }
                                   
                                </Col>   
                            </Row>
                            </CardBody>
                        </Card>
                    :""
                }
                <Modal
                    isOpen={nestedModal}
                    toggle={toggleNested}
                    
                >
                    <ModalHeader>Submit Schedule Request</ModalHeader>
                    <ModalBody>
                        {
                           selectedEvent ? 
                           <>
                            <p><b>Coach:</b> {selectedEvent.details.name}</p>
                            <p><b>Used Type:</b> {requestType.map((t,i) => ( t.value == selectedType ? t.label :"") )}</p>
                            <p><b>Date:</b> {selectedSchedule ? moment(selectedSchedule.start).format('ll') != moment(selectedSchedule.end).format('ll') ? moment(selectedSchedule.start).format('ll')+" - "+moment(selectedSchedule.end).format('ll'):  moment(selectedSchedule.start).format('ll') : "" }</p>
                            <p><b>Time:</b> {selectedSchedule ? moment(selectedSchedule.start).format('h:mm A')+ " - "+ moment(selectedSchedule.end).format('h:mm A') : ""}</p>

                           </>
                           : ""
                        }

                        <b>What do you want to cover in this session?<span className='text-danger'>*</span></b>
                        
                        <Input
                            id="sessionDescription"
                            name="sessionDescription"
                            className="mt-3"
                            placeholder="Type here..."
                            rows="5"
                            type="textarea"
                            onChange={e => setSessionDescription(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                    <Button color="danger" size="" onClick={toggleNested}>
                        Cancel
                    </Button>{' '}
                    <Button color="success" size="" disabled={sessionDescription? false: true} onClick={handleRequestTutorOnClick}>
                        Submit Request
                    </Button>{' '}
                    
                    </ModalFooter>
                </Modal>
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
        </Modal>

      </Container>
      
       <BuyTutoringHoursModal
        modalBuyTutoringHoursModal={modalBuyTutoringHoursModal}
        toggleBuyTutoringHoursModal={toggleBuyTutoringHoursModal}
      />

      <Plans ></Plans>


    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchUserDetails:state.fetchUserDetails,
    fetchAvailableSchedule: state.fetchAvailableSchedule,
    createRequestTutor: state.createRequestTutor,
    fetchRequestTutor: state.fetchRequestTutor,
    fetchStudentSchedule: state.fetchStudentSchedule,
    cancelRequestTutor: state.cancelRequestTutor,
    fetchRequestTutorHistory: state.fetchRequestTutorHistory,
    buyTutoringHoursModalStatus: state.buyTutoringHoursModalStatus,
    fetchCoaches: state.fetchCoaches,
    fetchSchedule: state.fetchSchedule
  };
};

export default connect(mapStateToProps)(ScheduleTutor);
