import React,{useEffect} from 'react'
import {Route, Redirect} from 'react-router-dom'
import {connect} from 'react-redux'
import AuthLayout from 'layouts/Auth'
import Swal from 'sweetalert2'
const  AuthRoute = ({component:Component, authenticated, admin, ...rest}) => {
    
    useEffect(() => {
        if(localStorage.getItem("FBIdToken")){
            Swal.fire({
                title: 'Loading...',
                text: 'Please wait',
                allowOutsideClick: false,
                didOpen: () => {
                  Swal.showLoading();
                },
                timer: 5000, // Set duration to 3 seconds
                // timerProgressBar: true, // Optional: shows a progress bar
              });
        }

    }, [])
    
    
    return (
        <Route {...rest}
            render = {(props) => {
                
                if (authenticated === true ){
                    if (admin === true ){
                        
                        return <Redirect to = "/admin/dashboard"/> 
                    } else {
                        
                        // return <Redirect to = "/client/dashboard"/>
                         return <Redirect to = "/client/community-free-members"/>
                    }
                } else {
                    return <AuthLayout><Component {...props}/></AuthLayout>
                }
            }
            }
        />
)}
const mapStateToProps = (state) => ({
    authenticated: state.loginAuth.authenticated,
    admin: state.loginAuth.admin
})

export default connect(mapStateToProps)(AuthRoute)
