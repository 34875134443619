import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  CardTitle, 
  CardSubtitle,
  FormGroup,
  Input,
  CardFooter,
  Button,
  Table,
  Badge,
TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Alert,
   Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label
} from "reactstrap";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import {
  fetchStudentRequestTutorHistory,
} from "redux/ManageTutorRequests/ManageTutorRequestsActions";
import UploadTutorVideoFileModal from "components/Modal/UploadTutorVideoFileModal";
import classnames from "classnames";
import DataTable from "react-data-table-component";
import { customDropdownWithBorder } from "util/reactCustomStyles";
const ManageStudentRequestHistory = (props) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState([]);
    const [requestTutorHistory, setRequestTutorHistory] = useState([]);

    const [itemDetails, setItemDetails] = useState();

    //Modal Behaviour
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [backdrop, setBackdrop] = useState(true);

    const showRequestDetails = (e,item) => {
      console.log(item)
      setItemDetails(item)
      setBackdrop(true);
      setModal(true);
    };
    useEffect(() => {
        dispatch(
            fetchStudentRequestTutorHistory({
            handle: props.userDetails.handle,
            })
        );
    }, []);

    useEffect(() => {
        if (props.fetchStudentRequestTutorHistory.loading) {
        Swal.fire({
            allowOutsideClick: false,
            didOpen: () => {
            Swal.showLoading();
            },
        });
        } else {
        Swal.close();
        }
        if (
        props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory
            .length
        ) {
        
        setRequestTutorHistory(
            props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory[0]
        );
            setData(  props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory[0]);
        setFilter(  props.fetchStudentRequestTutorHistory.fetchstudentrequesttutorhistory[0]);
        }
    }, [props.fetchStudentRequestTutorHistory]);


    const convertTimeToUTC = (time) => {
      return moment(moment(time).utcOffset(0, false), "HH:mm")
    }
    const convertTimeToLocal = (time) => {
      return moment(moment(time).utcOffset(0, true), "HH:mm")
    }


    const columns = [
        {
        name: "Student",
        selector: (row) => row.user.name,
        wrap: true,
        },
        {
          name: "Date",
          selector: (row) => {
        return moment(row.starttime).format('ll') != moment(row.endtime).format('ll') ? moment(row.starttime).format('ll')+" - "+moment(row.endtime).format('ll'):  moment(row.starttime).format('ll');
          },
        },
        {
        name: "Time",
        selector: (row) => {
          return convertTimeToLocal(row.starttime).format("h:mm A") + ' - ' + convertTimeToLocal(row.endtime).format("h:mm A")
        },
        wrap: true,
        },
        {
        name: "Type",
        selector: (row) => {
            return  row.requestType.toUpperCase() 
        },
        wrap: true,
        },
        {
        name: "Duration",
        selector: (row) => {
            return  row.duration 
        },
        wrap: true,
        },
        {
        name: "Status",
        selector: (row) => (
            <Badge color="" className="badge-dot mr-4">
            <i
                className={
                row.status == "pending"
                    ? "bg-info"
                    : row.status == "approved"
                    ? "bg-success"
                    : row.status == "disapproved"
                    ? "bg-danger"
                    : "bg-warning"
                }
            />
            {row.status}
            </Badge>
        ),
        },
        {
        name: "Action",
        cell: (row) => (
          <button
              onClick={(e) => showRequestDetails(e, row)}
                  className="btn btn-sm btn-primary"
              >
              Details
          </button>
                
        ),
            wrap: true,
            center: true,
            width: "300px",
        },
    ];
    
    const tableHeaderstyle = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px",
                // backgroundColor: "#ccc",
            },
        },
    };

    const convertDate = (item) => {
        return moment(item).utcOffset(0, false).format("YYYY-MM-DD");
    };


   return (
    <>
    <Card className="shadow">
      <CardBody>
        <CardTitle tag="h4" >STUDENT REQUESTS HISTORY</CardTitle>
        <CardSubtitle tag="h5" className="mb-2 text-muted">List of all records of your students past sessions </CardSubtitle>
        {data.length ? (
          
          <DataTable
            customStyles={tableHeaderstyle}
            columns={columns}
            data={filter}
            pagination
            wrap
            // selectableRows
            // fixedHeader
            selectableRowsHighlight
            highlightOnHover
            subHeader
            subHeaderComponent={
              <input
                type="text"
                className="w-50 form-control"
                placeholder="Search a keyword content..."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            }
            subHeaderAlign="right"
          />
        ) : (
          <Alert color="primary">No data available.</Alert>
        )}
      </CardBody>
    </Card>
     <Modal
        isOpen={modal}
        toggle={modalToggle}
        backdrop={backdrop}
        size="lg"
        centered
      >
        
        <ModalHeader toggle={modalToggle}>
         {'Schedule Id: '} {itemDetails ? itemDetails.docId : ""}
        </ModalHeader>
        <ModalBody className="pt-1">

        {
            itemDetails ? 
            <>

              <p><b>Student :</b> {itemDetails.user.name }</p>
              <p><b>Date :</b> {convertDate(itemDetails.date)}</p>
              <p><b>Time :</b> {itemDetails.starttime + ' - ' + itemDetails.endtime}</p>
              <p><b>Type :</b> {itemDetails.requestType.toUpperCase()}</p>
              <p><b>Duration :</b> {itemDetails.duration}</p>
              <p><b>Status :</b> <Badge color="" className="badge-dot mr-4">
                  <i
                    className={
                      itemDetails.status == "pending"
                        ? "bg-info"
                        : itemDetails.status == "approved"
                        ? "bg-success"
                        : itemDetails.status == "disapproved"
                        ? "bg-danger"
                        : "bg-warning"
                    }
                  />
                {itemDetails.status}
                </Badge> </p>
            
            {itemDetails.sessionDescription?
              <Row className="mt-2">
                <Col lg="12">
                  <b>Topic to discuss :</b>
                </Col>
                <Col lg="12" className="mt-3">
                  
                  {itemDetails.sessionDescription}
                  
                </Col>
              </Row>
            :""}
            </>
            : ""
          }

         
        </ModalBody>
      
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetails: state.loginAuth.userDetails,
    fetchStudentRequestTutorHistory: state.fetchStudentRequestTutorHistory
  };
};

export default connect(mapStateToProps)(ManageStudentRequestHistory);
