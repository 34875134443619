import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col,Button } from 'reactstrap';
// import './FullCourseWithSchedule.css'; // Assuming CSS is moved to an external file

const LiveSchedule = (props) => {
  const [schedData, setSchedData] = useState([]);
  const [cleanNewData, setCleanNewData] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  const sheetId = props.spreadSheetId;
  const sheetName = 'user-data';
  const query = encodeURIComponent('Select *');
  const url = `https://docs.google.com/spreadsheets/d/${sheetId}/gviz/tq?&sheet=${sheetName}&tq=${query}`;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const initFullCourseWithSchedules = async () => {
      try {
        const response = await fetch(url, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const text = await response.text();
        const jsonData = JSON.parse(text.substring(47).slice(0, -2));

        const colz = jsonData.table.cols.map((col) => col.label).filter(Boolean);
        const data = jsonData.table.rows.map((rowData) => {
          const values = {};
          colz.forEach((ele, ind) => {
            values[ele] = rowData.c[ind]?.f || rowData.c[ind]?.v || '';
          });



          const d = new Date(values.Date);
          const scheduleTime = values.Time.split(' ');
          let hour = parseInt(scheduleTime[0].split(':')[0], 10);

          if (values.Time.includes('AM') && hour === 12) hour = 0;
          if (values.Time.includes('PM') && hour !== 12) hour += 12;

          const minutes = scheduleTime[0].split(':')[1];
          const month = String(d.getMonth() + 1).padStart(2, '0');
          const date = String(d.getDate()).padStart(2, '0');

          values.DateTime = new Date(`${d.getFullYear()}-${month}-${date}T${String(hour).padStart(2, '0')}:${minutes}:00`);
          values.UniqueID = `${values.Coach}${values.Date.replace(/\//g, '')}${values.Time.replace(/\s|:/g, '')}`;

          return values;
        });

        data.sort(dynamicSort('DateTime', 1));
        setSchedData(data);
        filterUpcomingClasses(data);
      } catch (error) {
        console.error('Error fetching schedule data:', error);
      }
    };

    const filterUpcomingClasses = (data) => {
      const currentTime = new Date();
      let firstRow = 0;
      
      const filteredData = data.filter((value) => {
        // if(firstRow){
          const scheduleEndTime = new Date(value.DateTime);
          scheduleEndTime.setHours(scheduleEndTime.getHours() + 2);
          // return currentTime <= scheduleEndTime;
          return true
        // }
        // else{
        //   firstRow++
        //   return false
        // }
        
      });

      setCleanNewData(filteredData.sort(dynamicSort('DateTime', 1)));

    };

    const dynamicSort = (property, order) => {
      const sortOrder = order === -1 ? 1 : -1;
      return (a, b) => {
        const key1 = property === 'DateTime' ? a[property] : a[property]?.toUpperCase();
        const key2 = property === 'DateTime' ? b[property] : b[property]?.toUpperCase();
        return key1 < key2 ? sortOrder : key1 > key2 ? -sortOrder : 0;
      };
    };

    initFullCourseWithSchedules();
  }, []);

  // const getRemainingTime = (dateTime) => {
  //   // Convert current time to US Eastern Time
  //   const now = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
  //   const currentUSDate = new Date(now);
  
  //   const diff = new Date(dateTime) - currentUSDate;
  //   if (diff <= 0) return '00:00:00:00';
  
  //   const days = String(Math.floor(diff / (1000 * 60 * 60 * 24))).padStart(2, '0');
  //   const hours = String(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
  //   const minutes = String(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
  //   const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(2, '0');
  
  //   return `${days}:${hours}:${minutes}:${seconds}`;
  // };
  

  const getRemainingTime = (dateTime) => {
    // Get the current date in US Eastern Time as a timestamp
    const now = new Date();
    const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: "America/New_York",
        hourCycle: "h23",
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short"
    });

    const parts = formatter.formatToParts(now);

    const timeZoneAbbr = parts.find((p) => p.type === "timeZoneName").value; // Extract EDT or EST

    const currentUSDate = new Date(
        `${parts.find((p) => p.type === "year").value}-${parts.find((p) => p.type === "month").value.padStart(2, "0")}-${parts.find((p) => p.type === "day").value.padStart(2, "0")}T${parts.find((p) => p.type === "hour").value.padStart(2, "0")}:${parts.find((p) => p.type === "minute").value.padStart(2, "0")}:${parts.find((p) => p.type === "second").value.padStart(2, "0")}`
    );

    // Calculate the difference in milliseconds
    const diff = new Date(dateTime) - currentUSDate;
    if (diff <= 0) return "00:00:00:00";

    // Compute days, hours, minutes, and seconds
    const days = String(Math.floor(diff / (1000 * 60 * 60 * 24))).padStart(2, "0");
    const hours = String(Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, "0");
    const minutes = String(Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, "0");
    const seconds = String(Math.floor((diff % (1000 * 60)) / 1000)).padStart(2, "0");

    return [`${days}:${hours}:${minutes}:${seconds}`, timeZoneAbbr];
};

  const renderSchedules = () => {
    return cleanNewData.map((value, index) => {
      const d = new Date(value.Date);
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const borderNoRadius = index > 0 && index < cleanNewData.length - 1 ? 'border-no-radius' : '';
      const borderRadiusBottom = index === cleanNewData.length - 1 ? 'border-radius-bottom' : '';


      const remainingTime = getRemainingTime(value.DateTime)[0];
      const timeZoneAbbr = getRemainingTime(value.DateTime)[1]
      const isTimeOver = remainingTime === '00:00:00:00';

      return (typeof monthNames[d.getMonth()] != "undefined" ? 
        <Card className='shadow m-2'>
          <CardBody key={value.UniqueID} className={`coaches-list ${borderNoRadius} ${borderRadiusBottom}`}>
            
              <Row>
                <Col xl="2" lg="12" md="12" className='d-flex  align-items-center justify-content-sm-center order-sm-0'>
                    
                    <div>
                      <h2 className='text-xl'>{`${value.Day}, ${monthNames[d.getMonth()]} ${d.getDate()}`}</h2>
                      <h2 className='text-warning'>{`${value.Time} EST`}</h2>
                    </div>
                
                </Col>
                <Col xl="3" lg="12" md="12"  className="d-flex mt-sm-3  align-items-center justify-content-sm-center order-sm-2">
                  <div className="d-flex  align-items-center">
                    <img src={value.Image} alt={value["Coach Information"]} className="img_1_1 rounded-circle" />

                    <div className="ml-4 mr-4">
                      <h2 className="text-xl">{value.Coach}</h2>
                      <h2 className='text-gray'><em>TPC Coach</em></h2>
                    </div>
                  </div>

                </Col>
                
                <Col xl="5" lg="12" md="12" className='d-flex mt-sm-3 align-items-center text0 text-sm-center justify-content-sm-center order-sm-1'>
                  <div >
                    <h2 className='text-xl'> <span className="text-primary">{value["Session Type"]}</span> - {value.Topic}</h2>
                    <h2>{value["Uworld ID/ Powerbook Pages/ AAMC Question #"]}</h2>
                  </div>
                </Col>

                <Col xl="2" lg="12" md="12" className='d-flex mt-lg-0 mt-md-5 mt-sm-5 justify-content-center align-items-center order-sm-3'>
                  
                    {remainingTime <= 0 ? (
                      <a href={value["Zoom Link"]} className="btn btn-info text-xl" target="_blank" rel="noopener noreferrer">
                        Join Now
                      </a>
                    ) : (
                      <Button id={value.UniqueID} className="btn btn-warning text-xl">
                        {remainingTime}
                      </Button>
                    )}
                  
                </Col>
                
              </Row>
              
            
          </CardBody>
        </Card> : ""
      );
    });
  };

  return <div id="full_course_with_schedule">{renderSchedules()}</div>;
};

export default LiveSchedule;
